import React from "react";
import classNames from "classnames";
import { AnswerEntity } from "../../generated/graphql";
import "./answer.scss";

interface Props {
  isCorrect: string;
  answer: AnswerEntity;
  showLoading: boolean;
  onAnswerSelect: Function;
  isAnswerSelected: boolean;
}

export const Answer = (props: Props) => {
  const { answer } = props;
  const onAnswerSelect = () => {
    props.onAnswerSelect(answer.id, "");
  };
  return (
    <div
      className={classNames("mstr-answer", props.isCorrect, {
        "answer-selected": props.isAnswerSelected,
      })}
      onClick={onAnswerSelect}
    >
      <div className="answer-container" dangerouslySetInnerHTML={{ __html: answer.content_html! }}></div>
      {props.showLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {/* {props.isCorrect && (
        <div className="answer-feedback-icon">
          {props.isCorrect === "YES" && <span>✓</span>}
          {props.isCorrect === "NO" && <span>✗</span>}
          {props.isCorrect === "CNBC" && <span>‼</span>}
        </div>
      )} */}
    </div>
  );
};
