import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { LearnerDetailsOutput, SectionEntity } from "../../generated/graphql";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { SectionContentPartial } from "../../partials/section-content/section-content.partial";
import { SectionStartConnector } from "../../components/section-start-connector/section-start-connector";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MessageWithExclamation } from "../../components/section-go-back-warning/section-go-back-warning";
import { Question } from "../../components/question/question.component";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import { NextSectionConnector } from "../../components/next-section-connector/next-section-connector";

const GET_SECTION_DETAILS = gql`
  query Section($id: Int!) {
    getLearnerDetails {
      first_name
      last_name
    }
    section(id: $id) {
      id
      section_header
      content
      content_html @client
      referencedocs
      questions {
        id
        uuid @client
        question_text
        content
        content_html @client
        static_content_html @client
        static_content
        post_content
        show_additional_content
        explanation
        section_id
        is_ml
        explain_on_correct
        correct_explanation
        answers {
          id
          content
          content_html @client
        }
      }
    }
  }
`;

interface NextSectionData {
    section: SectionEntity;
    getLearnerDetails: LearnerDetailsOutput;
}

export default function LinearFlowElementPreview() {
    let { id } = useParams<any>();

    const [showSection, setSectionShown] = useState(false);

    const { loading, error, data: sectionData } = useQuery<NextSectionData>(
        GET_SECTION_DETAILS,
        {
            variables: { id: +id },
        }
    );

    if (loading) return <FullScreenLoader />;
    if (error) return <div>ERROR</div>;

    return (
        <div className="px-5 pb-5">
            <SectionStartConnector
                onComplete={() => setSectionShown(true)}
                sectionId={+id}
                section_header={sectionData?.section.section_header!}
                setUserListenedToElementSpeech={() => { }}
                sortid={1}
                isPreview={true}
            />


            {showSection && (
                <FadeIn duration={2}>
                    <SectionContentPartial
                        fade={false}
                        section={sectionData?.section!}
                    />
                    <MessageWithExclamation
                        message="Remember, you won't be able to return back to the section when we start our discussion"
                    />

                    <MstrButton
                        showLoading={false}
                        disabled={true}
                        text="Continue"
                        onClick={() => {
                        }}
                    />

                    <div className="pt-12">
                        <TypeText
                            isPreview={true}
                            onComplete={() => {}}
                        >
                            Let's start our discussion.
                        </TypeText>
                    </div>

                    {sectionData?.section.questions.map((question, index) => (
                        <Question
                            userListenedToElementSpeech={true}
                            section_id={+id}
                            initiative_id={0}
                            isCorrect={""}
                            learnerDetails={sectionData.getLearnerDetails}
                            previousQuestionId={
                                sectionData.section.questions.length > 1 ? sectionData.section.questions[sectionData.section.questions.length - 2].id : -1
                            }
                            questionIndex={index}
                            onQuestionAttempt={() => { }}
                            onQuestionFlowComplete={() => { }}
                            key={(question as any).uuid}
                            currentQuestionUUID={0}
                            isQuestionResponseLoading={false}
                            question={question}
                            dontBlur={true}
                            isPreview={true}
                        />
                    ))}

                    <NextSectionConnector
                        isPreview={true}
                        currentSectionId={+id}
                        navigateToNextSection={() => {}}
                        nextSectionId={+id}
                        refdocs={sectionData?.section.referencedocs || []}
                    />
                </FadeIn>
            )}

        </div>
    )
}