import React from "react";
import moment from "moment";
import { ChatConversationEntity } from "../../../../../../generated/graphql";

interface Props {
  conversation: ChatConversationEntity;
}

export const LastMessageDate = (props: Props) => {
  const { conversation } = props;
  if (!conversation.last_message) {
    return null;
  }
  return (
    <div className="text-xs text-gray-500">
      {moment(conversation.last_message.createdAt).fromNow()}
    </div>
  );
};
