import React from "react";

export const Slideshow4 = () => {
  return (
    <div>
      <img src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/metal-venetian-blinds.jpg" />
      <div className="py-6">
        Venetian blinds aren’t limited to real or faux wood. Metal Venetian
        blinds are built using high quality aluminium which is tough against
        moisture and wear and tear. The horizontal slats not only make them
        stylish and sleek, but like their wooden counterparts, they are equally
        functional too. The slats can be accurately angled to allow for the
        perfect balance between privacy and light.
      </div>
    </div>
  );
};
