import React from "react";
import { FadeIn } from "../fade-in/fade-in";
import loaderImage from "../../images/loading-animation.gif";

export const FullScreenLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FadeIn>
        <img
          alt="Loader"
          style={{ width: 300 }}
          src={loaderImage}
        />
      </FadeIn>
    </div>
  );
};
