import React from "react";
import "./dot-separator.scss";

export const DotSeparator = () => {
  return (
    <div className="dotseparator">
      <span className="sepdots">.</span>
      <span className="sepdots">.</span>
      <span className="sepdots">.</span>
    </div>
  );
};
