import React from "react";

import qf from "./images/qf.png";
import mdf from "./images/mdf.png";
import play from "./images/play.png";

type iBotMessageLink = {
    url: string;
    name: string;
    type: "QF" | "MDF" | "INITIATIVE"
}

type iBotMessage = {
    text: string;
    links: iBotMessageLink[];
}

const message: iBotMessage = {
    text: "To measure a window for blinds, measure the width at the top, middle, and bottom; use the smallest width. Measure the height from the top to the sill on the left, center, and right; use the largest height. Note if mounting inside or outside the window frame.",
    links: [
        {
            type: "QF",
            url: "",
            name: "Steps to measure large windows",
        },
        {
            type: "MDF",
            url: "",
            name: "Things to consider when measuring uneven windows",
        },
        {
            type: "INITIATIVE",
            name: "Bypass and Butt Shades",
            url: "",
        }
    ]
}

export const AssistantChatMessageBot = () => {
    return (
        <div className="text-base mr-10">
            <div className="bg-blue-200 text-blue-500 p-4 rounded-lg">
                {message.text}


                <br /><br />
                Or, you can use the following links for more information.

                <div className="space-y-4 mt-2">
                    {message.links.map(link => (
                        <div className="flex items-start">
                            {link.type === "QF" && <img src={qf} className="q-6 h-6 mr-1" />}
                            {link.type === "MDF" && <img src={mdf} className="q-6 h-6" />}
                            {link.type === "INITIATIVE" && <img src={play} className="q-5 h-5 mr-1" />}

                            <div className="pl-2">
                                {link.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}