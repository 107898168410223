import React, { useEffect } from "react";
import { eventBus } from "../../event-bus/event-bus";
import { gql, useMutation, useQuery } from "@apollo/client";

const SAVE_USER_EVENT = gql`
  mutation SaveUserEvent($event: String!, $details: String!) {
    saveUserEvent(event: $event, details: $details)
  }
`;

export const UserEvents = () => {
  const onUserEvent = (data: any) => {
    console.log("User Event: ", data);
    saveUserEvent({
      variables: {
        event: data.event,
        details: JSON.stringify(data.details),
      },
    });
  };

  useEffect(() => {
    eventBus.on("USER_EVENT", onUserEvent);
  }, []);

  const [saveUserEvent] = useMutation(SAVE_USER_EVENT, {});

  return <></>;
};
