import React from "react";
import { UserEntity } from "../../../../../../generated/graphql";

interface Props {
  users: UserEntity[];
}

export const ConversationParticipantNames = (props: Props) => {
  return (
    <div className="mt-1">
      {props.users.map((p) => p.first_name + " " + p.last_name).join(", ")}
    </div>
  );
};
