import React from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

export function Slide5() {
  return (
    <SlideshowLightbox className="container grid grid-cols-2 gap-2 mx-auto">
      <img
        className="w-full rounded"
        src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/real-wooden-blinds.jpg"
      />
      <img
        className="w-full rounded"
        src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/faux-wood-blinds.jpg"
      />
      <img
        className="w-full rounded"
        src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/metal-venetian-blinds.jpg"
      />
      <img
        className="w-full rounded"
        src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/roller-blinds.jpg"
      />
    </SlideshowLightbox>
  );
}

// ("https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/real-wooden-blinds.jpg");
// ("https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/faux-wood-blinds.jpg");
// ("https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/metal-venetian-blinds.jpg");
// ("https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/roller-blinds.jpg");
