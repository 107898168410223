import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TypeText } from "../../type-text/type-text";
import { MstrButton } from "../../mstr-button/mstr-button";
import { FadeIn } from "../../fade-in/fade-in";
import Typist from "react-typist";
import ReactHtmlParser from "react-html-parser";
import ReactImageMagnify from "react-image-magnify";
import { NONAME } from "dns";

const getRandomButtonMessage = () => {
  const messages = [
    `Okay, that was helpful.`,
    `Got it!`,
    `Okay, let's move on.`,
    `Alright!`,
    `Great, let's continue.`,
  ];
  return messages[Math.floor(Math.random() * messages.length)];
};

export const AnimatedImage = (props: any) => {
  const [showCaption, setShowCaption] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [buttonMessage] = useState(getRandomButtonMessage());
  const [dimension, setDimension] = useState<{
    height: number;
    width: number;
  }>();

  const showButtonOrNextBlock = () => {
    if (props.showAcknowledgeButton && !props.isLastBlock) {
      setTimeout(() => setShowButton(true), 1500);
    } else {
      setTimeout(() => props.showNextBlock(), 1500);
    }
  };

  
  const onImgLoad = (data: any) => {
    setDimension({
      height: data.target.offsetHeight,
      width: data.target.offsetWidth,
    });
  };

  if (!dimension) {
    return (
      <div style={{ opacity: 0, position: "absolute" }}>
        <img src={props.block.data.url || props.block.data.file.url} onLoad={onImgLoad} />
      </div>
    );
  }

  return (
    <motion.div>
      {/* <ReactImageMagnify
        enlargedImagePosition="over"
        {...{
          smallImage: {
            alt: "Wristwatch by Ted Baker London",
            isFluidWidth: true,
            src: props.block.data.url,
            onLoad: () => {
              console.log("Image Loaded");
              if (props.block.data.caption) {
                setShowCaption(true);
              } else {
                showButtonOrNextBlock();
              }
            },
          },
          largeImage: {
            src: props.block.data.url,
            width: dimension.width,
            height: dimension.width,
          },
        }}
      /> */}

      <motion.img
        onLoad={() => {
          showButtonOrNextBlock();
          // if (props.block.data.caption) {
          //   setShowCaption(true);
          // } else {
          //   showButtonOrNextBlock();
          // }
        }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        initial={{ opacity: 0, y: 200, scale: 0.8 }}
        transition={{ duration: 1 }}
        onAnimationComplete={() => {}}
        style={{ width: "100%" }}
        src={props.block.data.url|| props.block.data.file.url}
      />

      {/* {showCaption && (
        <div style={{ paddingBottom: 20 }}>
          <Typist
            className={`type-text`}
            startDelay={100}
            cursor={{
              blink: true,
              hideWhenDone: true,
              hideWhenDoneDelay: 10,
            }}
            avgTypingDelay={40}
            onTypingDone={() => showButtonOrNextBlock()}
          >
            {ReactHtmlParser(props.block.data.caption)}
            <Typist.Delay ms={!!props.delay ? props.delay : 1500} />
          </Typist>
        </div>
      )} */}
      <AnimatePresence>
        {showButton && !buttonClicked && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
          >
            <FadeIn>
              <MstrButton
                text={buttonMessage}
                onClick={() => {
                  setButtonClicked(true);
                  setTimeout(() => props.showNextBlock(), 0.5);
                }}
              />
            </FadeIn>
          </motion.div>
        )}
      </AnimatePresence>
      <br />
    </motion.div>
  );
};
