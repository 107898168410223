import { gql, useMutation, useQuery } from "@apollo/client";
import {Redirect, useHistory, useParams} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { AnimatedTypedTextWithPause } from "../../components/animated-content/animated-typed-text-with-pause/animated-typed-text-with-pause";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import { UnitEntity, UnitReviewEntity } from "../../generated/graphql";
import thumb from "./thumb.png";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { FadeIn } from "../../components/fade-in/fade-in";
import classNames from "classnames";

import confetti from "canvas-confetti";

var count = 200;
var defaults = {
  origin: { y: 0.7 }
};

function fire(particleRatio: number, opts: object) {
  confetti(Object.assign({}, defaults, opts, {
    particleCount: Math.floor(count * particleRatio)
  }));
}

const getAnimateVoteImg = (delay?: number) => {
  return {
    animate: {
      height: 64,
      width: 64,
    },
    initial: {
      height: 0,
      width: 0,
    },
    exit: {
      height: 0,
      width: 0,
    },
    transition: { duration: 0.4, delay: delay || 0 },
  };
};

const GET_USER_REVIEWS = gql`
  query GetUserInitiativesReviews($unit_id: Int!) {
    getReviewsByUnitUser(unit_id: $unit_id) {
      id
      comment
    }
    initiative(unit_id: $unit_id) {
      is_private
    }
  }
`;

interface UserReviewsData {
  initiative: UnitEntity;
  getReviewsByUnitUser: UnitReviewEntity[];
}

const SAVE_INITIATIVE_FEEDBACK = gql`
  mutation SaveInitiativeFeedbackReview(
    $initiative_id: Int!
    $rating: String!
    $comment: String
    $question: String
    $answer: String
  ) {
    saveInitiativeFeedbackReview(
      initiative_id: $initiative_id
      rating: $rating
      comment: $comment
      question: $question
      answer: $answer
    ) {
      id
      unit
      user
      rating
      comment
    }
  }
`;
interface InitiativeFeedbackData {
  saveInitiativeFeedbackReview: UnitReviewEntity;
}

const positive = [
  {
    question: "We are glad to hear that! What was especially helpful?",
    answer: '{{first_name}} said "{{comment}}" was especially helpful.',
  },
  {
    question: "That's good to hear! What part of this initiative is most helpful for you in the work you do?",
    answer: `{{first_name}} said that "{{comment}}" were the most helpful for the work {{first_name}} does.`,
  },
  {
    question: "That is great to hear. What did you like about this initiative?",
    answer: `{{first_name}} liked "{{comment}}" in this initiative.`,
  }
];

const negative = [
  {
    question: "We're sorry to hear that. How can this initiative be improved?",
    answer: `{{first_name}} said "{{comment}}" can be improved.`,
  },
  {
    question: "We're sorry to hear that. What can we do better the next time?",
    answer: `{{first_name}} said "{{comment}}" as feedback to improve this initiative.`,
  },
  {
    question: "Thanks for letting us know. How can this initiative be improved?",
    answer: `{{first_name}} said "{{comment}}" can be improved.`,
  }
]

export const InitiativeFeedback2 = () => {
  let { id: initiativeId } = useParams<any>();
  console.log('Initiative id for feedback: ', initiativeId);
  const [showVoteQuestion, setShowVoteQuestion] = useState(false);
  const [showVoteImages, setShowVoteImages] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);

  const [positiveQuestion] = useState(positive[Math.floor(Math.random() * positive.length)]);
  const [negativeQuestion] = useState(negative[Math.floor(Math.random() * negative.length)]);

  const showFireworks = () => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
    
  }

  useEffect(() => {
    setTimeout(() => {
      showFireworks();
    }, 1500);
  }, []);

  const { data, error, loading } = useQuery<UserReviewsData>(
    GET_USER_REVIEWS,
    {
      variables: {
        unit_id: +initiativeId,
      },
    }
  );

  if(data) {
    console.log({
      reviewData: data,
    })
  }

  const history = useHistory();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [saveInitiativeFeedback] = useMutation<InitiativeFeedbackData>(
    SAVE_INITIATIVE_FEEDBACK,
    {
      update: (cache, response) => {
        console.log("Got Unit Faadbeck response: ", response);
      },
    }
  );
  const [vote, setVote] = useState("");

  if(data && (data.getReviewsByUnitUser.length || data.initiative.is_private === 1)) {
    return (
      <div className="p-4 w-full">
        <AnimatedTypedTextWithPause
          startDelay={500}
          showNextBlock={() => {
            console.log('DONE GO HOME');
          }}
          block={{
            data: {
              text:
                "Great work on completing the initiative.",
            },
          }}
        />
        <br />
          <FadeIn >
            <MstrButton text="Home" onClick={() => {
              setTimeout(() => history.push(`/home`), 100);
            }} />
          </FadeIn>
      </div>
    )
  }

  if(loading) {
    return <FullScreenLoader />
  }

  return (
    <div
      className={classNames(
        "px-6 pt-4 flex-1 initiative-feedback-container",
        !vote ? "show-firecrackers" : ""
      )}
    >
      {/* {!vote && (
        <>
          <div className="firework"></div>
          <div className="firework"></div>
          <div className="firework"></div>
        </>
      )} */}
      
      <AnimatedTypedTextWithPause
        startDelay={1500}
        showNextBlock={() => {
          setTimeout(() => {
            setShowVoteQuestion(true);
          }, 700);
        }}
        block={{
          data: {
            text:
              "Great work on completing the initiative.",
          },
        }}
      ></AnimatedTypedTextWithPause>

      {showVoteQuestion && (
        <AnimatedTypedTextWithPause
          startDelay={1500}
          showNextBlock={() => {
            setShowVoteImages(true);
          }}
          block={{
            data: {
              text: "How was this initiative?",
            },
          }}
        ></AnimatedTypedTextWithPause>
      )}

      {showVoteImages && (
        <AnimatePresence>
          <div className="flex justify-center py-12">
            {(!vote || vote === "THUMBS-UP") && (
              <motion.img
                onClick={() => setVote("THUMBS-UP")}
                {...getAnimateVoteImg(0)}
                className="mx-12"
                src={thumb}
              />
            )}
            {(!vote || vote === "THUMBS-DOWN") && (
              <motion.img
                onClick={() => setVote("THUMBS-DOWN")}
                {...getAnimateVoteImg(1)}
                className="mx-12 transform rotate-180"
                src={thumb}
              />
            )}
          </div>
        </AnimatePresence>
      )}

      {vote && vote === "THUMBS-UP" && (
        <AnimatedTypedTextWithPause
          startDelay={1500}
          showNextBlock={() => {
            setShowFeedbackBox(true);
          }}
          block={{
            data: {
              text: positiveQuestion.question,
            },
          }}
        ></AnimatedTypedTextWithPause>
      )}

      {vote && vote === "THUMBS-DOWN" && (
        <AnimatedTypedTextWithPause
          startDelay={1500}
          showNextBlock={() => {
            setShowFeedbackBox(true);
          }}
          block={{
            data: {
              text: negativeQuestion.question,
            },
          }}
        ></AnimatedTypedTextWithPause>
      )}

      <br />
      {showFeedbackBox && (
        <div>
          <textarea
            rows={5}
            value={feedbackComment}
            onChange={(e) => setFeedbackComment(e.target.value)}
            placeholder="Please share your feedback here..."
            className="bg-transparent feedback-text border outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-300 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      )}

      <br />
      {feedbackComment && !feedbackSubmitted && (
        <div>
          <MstrButton
            onClick={() => {
              saveInitiativeFeedback({
                variables: {
                  initiative_id: +initiativeId,
                  comment: feedbackComment,
                  rating: vote,
                  question: vote === "THUMBS-UP" ? positiveQuestion.question : negativeQuestion.question,
                  answer: vote === "THUMBS-UP" ? positiveQuestion.answer : negativeQuestion.answer,
                },
              });
              setFeedbackSubmitted(true);
            }}
            text="Submit feedback"
          />
        </div>
      )}
      {feedbackSubmitted && (
        <div className="mt-3">
          <TypeText
            onComplete={() => {
              setTimeout(() => history.push(`/home`), 1000);
            }}
          >
            Thank you for your feedback.
          </TypeText>
        </div>
      )}
    </div>
  );
};
