import React from "react";
import thumb from "./thumb.png";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  isSelected: boolean;
}

export const ThumbsDown = (props: Props) => {
  return (
    <img
      className={classNames(
        "transform rotate-180",
        props.isSelected ? "" : "opacity-50"
      )}
      src={thumb}
    />
  );
};
