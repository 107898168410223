import React from "react";
import { AssistantLoader } from "./assistant-loader";
import { AssistantExamples } from "./assistant-examples";
import { AssistantFabButton } from "./assistant-fab-button";

export const AssistantPage = () => {
    return (
        <div className="flex flex-col items-center flex-1" style={{
            background: "#60818b1a",
        }}>
            <AssistantLoader />
            <div className="text-base px-6">
                Hi! I'm your MindStrength assistant. You can ask me anything like -
            </div>
            <AssistantExamples />
            <div className="absolute bottom-5 right-5">
                <AssistantFabButton />
            </div>
        </div>
    )
}