import React from "react";
import "./notifications.scss";
import { gql, useMutation, useQuery } from "@apollo/client";

import { NotificationsEntity } from "../../../../generated/graphql";
// import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import { userInfo } from "os";
import { useHistory } from "react-router";

const isIdc = window.location.pathname.indexOf("idc") > -1;

interface NotificationsData {
  ihNotifications: Array<NotificationsEntity>;
}

const MARK_NOTIFICATION_READ = gql`
  mutation MarkNotificationAsRead($id: Int!) {
    markNotificationAsRead(id: $id) {
      id
      is_read
    }
  }
`;

let GET_NOTIFICATIONS: any;

if (isIdc) {
  GET_NOTIFICATIONS = gql`
    query IdcNotifications {
      idcNotifications {
        id
        comment
        type
        createdAt
        section_id
        initiative_id
        chapter_id
        is_read
        mentioned_by_user {
          id
          username
          first_name
          last_name
          avatar_url
          initials @client
        }
      }
    }
  `;
} else {
  GET_NOTIFICATIONS = gql`
    query IHNotifications {
      ihNotifications {
        id
        comment
        type
        createdAt
        section_id
        is_read
        initiative_id
        chapter_id
        mentioned_by_user {
          id
          username
          first_name
          last_name
          avatar_url
          initials @client
        }
      }
    }
  `;
}

interface Props {
  closeNotificationsDrawer?: Function;
}

export const NotificationsScreen = (props: Props) => {
  const { loading, data } = useQuery<NotificationsData>(GET_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network"
  });

  const [markNotificationAsRead] = useMutation<any>(MARK_NOTIFICATION_READ, {
    update: () => {},
  });

  const history = useHistory();

  if (loading) return <div />;

  const list: NotificationsEntity[] = isIdc
    ? (data as any).idcNotifications
    : data?.ihNotifications;

  return (
    <div className="notification-list p-3">
      {list.map((notification) => (
        <div
          key={notification.id}
          className="user-review-comment border border-gray-200 p-6"
          style={{ background: notification.is_read ? "transparent" : "rgba(0,0,0,0.06)" }}
          onClick={() => {
            if (!notification.is_read) {
              markNotificationAsRead({
                variables: {
                  id: notification.id,
                },
              });
            }
            if (notification.type === "REVIEW_COMMENT_MENTION") {
              if (notification.section_id && notification.chapter_id) {
                if (props.closeNotificationsDrawer) {
                  props.closeNotificationsDrawer();
                }
                history.push(
                  `/initiative/review/${notification.chapter_id}/${notification.section_id}`
                );
              }
            }
          }}
        >
          <div className="notification-header">
            {notification.mentioned_by_user?.avatar_url && (
              <img
                className="user-avatar"
                src={notification.mentioned_by_user.avatar_url}
              />
            )}
            {!notification.mentioned_by_user?.avatar_url && (
              <div className="user-initials">
                {(notification.mentioned_by_user as any).initials}
              </div>
            )}
            <div className="user-name">
              <b>
                {notification.mentioned_by_user?.first_name}{" "}
                {notification.mentioned_by_user?.last_name}
              </b>{" "}
              sent you a notification.
              <div className="from-now">
                {moment(notification.createdAt).fromNow()}
              </div>
            </div>
          </div>
          <div
            className="comment"
            style={{ fontWeight: notification.is_read ? "normal" : 500 }}
            dangerouslySetInnerHTML={{ __html: notification.comment }}
          />
        </div>
      ))}
    </div>
  );
};
