import React from "react";
import "./section-go-back-warning.scss";
import exclamationImage from "../../images/blue-exclaim.png";
import { getFontSize } from "../../AppFont";

interface Props {
  message: string;
}

export const MessageWithExclamation = (props: Props) => {
  return (
    <div className="return-section-warning mt-5">
      <div style={{ flex: 1, fontSize: getFontSize(), }}>{props.message}</div>
      <div>
        <img
          alt=""
          className="exclamation-mark animate__flash animate__animated animate__infinite"
          src={exclamationImage}
        />
      </div>
    </div>
  );
};
