import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import "./forgot-password-reset.scss";
import { useHistory } from "react-router-dom";
import lock from "./lock.png";

const UPDATE_LEARNER_PASSWORD = gql`
  mutation UpdateLearnerPasswordFromResetToken(
    $token: String!
    $password: String!
  ) {
    updateLearnerPasswordFromResetToken(token: $token, password: $password) {
      error
    }
  }
`;

interface UpdatePasswordData {
  updateLearnerPasswordFromResetToken: {
    error: boolean;
  };
}

interface TokenDetailsData {
  getForgotTokenDetails: string;
}

export const ForgotLearnerPasswordResetScreen = (props: any) => {
  console.log("Params: ", (props as any).match.params);
  const token_str = (props as any).match.params.token;
  const [showFields, setShowFields] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const history = useHistory();

  const GET_TOKEN_DETAILS = `
    query {
      getForgotTokenDetails(token: ${token_str})
    }
  `;

  const { data, error, loading } = useQuery<TokenDetailsData>(
    gql(GET_TOKEN_DETAILS)
  );

  const [changePassword] = useMutation<UpdatePasswordData>(
    UPDATE_LEARNER_PASSWORD,
    {
      update: (cache, response) => {
        setShowLoading(false);
        console.log("Got update password: ", response);
        setShowSuccess(true);
      },
    }
  );

  if (data) {
    if (!data.getForgotTokenDetails.length) {
      console.log("Not valid token");
      history.push("/home");
    }
  }

  const updatePassword = () => {
    setShowLoading(true);
    changePassword({
      variables: {
        password,
        token: token_str,
      },
    });
  };

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => setShowFields(true)}>
        Hi, let's set you up with a secure password. Remember to keep it safe
        with you.
      </TypeText>
      <br />
      {showFields && (
        <FadeIn onComplete={() => setShowButton(true)}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ height: 40 }}
              src={lock}
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="text-password"
              type="text"
            />
          </div>
        </FadeIn>
      )}
      <br />
      {showButton && !showSuccess && (
        <FadeIn>
          <MstrButton
            onClick={updatePassword}
            disabled={password.length < 6}
            width={250}
            showLoading={showLoading}
            text="Update password"
          ></MstrButton>
        </FadeIn>
      )}
      <br />
      {showSuccess && (
        <TypeText
          onComplete={() => {
            setTimeout(() => history.push("/home"), 2000);
          }}
        >
          Your password has been reset successfully.
        </TypeText>
      )}
    </div>
  );
};
