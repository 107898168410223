import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { eventBus } from "../../event-bus/event-bus";
import { QuickFlowModal } from "./quickflow-modal";

export const QuickFlow = (props: any) => {
  const document_id = +(props as any).match.params.id;
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      eventBus.dispatch("USER_EVENT", {
        event: "REFERENCE_DOCUMENT_READ",
        details: {
          object_id: document_id,
        },
      });
    }, 1500);
  }, []);
  return <QuickFlowModal closePreview={() => history.replace("/home")} refDocId={document_id} />
};
