import React from "react";
import { AssistantChatMessageBot } from "./assistant-chat-message-bot";
import { AssistantChatMessageUser } from "./assistant-chat-message-user";
import { AssistantMessageInput } from "./assistant-message-input";

export const AssistantChat = () => {
    return (
        <div className="text-base p-4 flex flex-col">
            <div className="flex-1 space-y-4">
                <AssistantChatMessageUser />
                <AssistantChatMessageBot />
            </div>
            <AssistantMessageInput />
        </div>
    )
}