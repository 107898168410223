import React from "react";
import "./learner-progress.scss";

interface Props {
  progress: Number;
}

export const LearnerProgress = (props: Props) => {
  return (
    <div
      className="learner-progress"
      style={{
        width: props.progress + "%",
      }}
    ></div>
  );
};
