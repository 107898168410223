import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

interface Props {
  id?: number;
  url: string;
  extension: string;
  closePreview?: Function;
}

export const RefdocPreview = (props: Props) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  function pageUp() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function pageDown() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  return (
    <>
      <div className="doc-preview-container mt-9">
        {props.closePreview && (
          <div className="preview-header">
            <div
              className="close"
              onClick={() => {
                window.open(props.url, "_blank");
              }}
            >
              Open in new tab
            </div>

            <div
              className="close"
              onClick={() => {
                if (props.closePreview) {
                  props.closePreview();
                }
              }}
            >
              Close
            </div>
          </div>
        )}
        <iframe src={props.url} height={'100%'}/><br/>
        {/*<Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>*/}
        {/*  <Page pageNumber={pageNumber} />*/}
        {/*</Document>*/}

        <p className="preview-navigation">
          <span onClick={pageDown} className="arrow">
            ←
          </span>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp; Page {pageNumber} of {numPages}
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <span onClick={pageUp} className="arrow">
            →
          </span>
        </p>
      </div>
    </>
  );
};
