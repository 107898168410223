import React from "react";
import { motion } from "framer-motion";

interface Props {
  duration?: number;
  animateHeight?: boolean;
  onComplete?: Function;
}

export const FadeIn: React.FunctionComponent<Props> = (props) => {
  return (
    <motion.div
      onAnimationComplete={() => {
        if (props.onComplete) props.onComplete();
      }}
      transition={{ duration: props.duration || 1 }}
      initial={{ opacity: 0, height: props.animateHeight ? 0 : "auto" }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: props.animateHeight ? 0 : "auto" }}
    >
      {props.children}
    </motion.div>
  );
};
