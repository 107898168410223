import React from "react";
import Typist from "react-typist";
import "./animated-typed-text.scss";
import ReactHtmlParser from "react-html-parser";

const domparser = new DOMParser();
export const AnimatedTypedText = (props: any) => {
  const { block } = props;
  const text = block.data.text;
  // .replaceAll("&nbsp;", " ");

  let innerContent;
  if (block.type === "paragraph") {
    innerContent = `<div class="mstr-type-text">${text}</div>`;
  } else if (block.type === "header" && block.data.level === 1) {
    innerContent = `<h1 class="mstr-type-text">${text}</h1>`;
  } else if (block.type === "header" && block.data.level === 2) {
    innerContent = `<h2 class="mstr-type-text">${text}</h2>`;
  } else if (block.type === "header" && block.data.level === 3) {
    innerContent = `<h3 class="mstr-type-text">${text}</h3>`;
  } else if (block.type === "header" && block.data.level === 4) {
    innerContent = `<h4 class="mstr-type-text">${text}</h4>`;
  } else if (block.type === "header" && block.data.level === 5) {
    innerContent = `<h5 class="mstr-type-text">${text}</h5>`;
  } else {
    innerContent = `<div class="mstr-type-text">${text}</div>`;
  }

  return (
    <Typist
      className="animated-text"
      cursor={{
        blink: true,
        show: false,
        hideWhenDone: true,
        hideWhenDoneDelay: 10,
      }}
      avgTypingDelay={40}
      onTypingDone={() => {
        props.showNextBlock();
      }}
    >
      {ReactHtmlParser(innerContent)}
      <Typist.Delay ms={1500} />
    </Typist>
  );
};
