import React from "react";
import { UserEntity } from "../../../../generated/graphql";
import { UserAvatar } from "../user-avatar/user-avatar";

interface Props {
  person: UserEntity;
  isSelected: boolean;
}

export const UserItem = (props: Props) => {
  const { isSelected } = props;
  const { person } = props;
  return (
    <li
      className="list-none ml-0"
      key={person.id}
      style={{
        background: isSelected ? "rgba(55, 65, 81, 0.15)" : "transparent",
      }}
    >
      <div className="relative px-3 py-3 flex items-center space-x-3 hover:bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
        <div className="flex-shrink-0">
          <UserAvatar user={person} />
        </div>
        <div className="flex-1 min-w-0">
          <a className="focus:outline-none">
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            <div className="text-sm font-medium text-gray-900">
              {person.first_name} {person.last_name}
            </div>
            <div className="text-xs text-gray-500 truncate">{person.email}</div>
          </a>
        </div>
      </div>
    </li>
  );
};
