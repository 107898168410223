import React, { useState } from "react";
import { motion } from "framer-motion";
import { eventBus } from "../../../event-bus/event-bus";
import { useEffect } from "react";

export const AnimatedVideo = (props: any) => {
  const [videoId] = useState<number>(
    parseInt(Math.random() * 10000000000 + "")
  );
  const [isNextBlockCalled, setNextBlockCalled] = useState(false);

  const [videoWatchedDuration, setVideoWatchedDuration] = useState(0);
  const [videoTotalDuration, setVideoTotalDuration] = useState(0);

  const videoWatchedDurationStateRef = React.useRef(videoWatchedDuration);
  const videoTotalDurationStateRef = React.useRef(videoTotalDuration);

  const [videoCompleted, setVideoCompleted] = useState(false);

  const { onVideoStart, onVideoPause } = props;

  const videoSubscription = () => {
    if(isVideoGif()) {
      return false;
    }
    
    const watchPerc = (
      (videoWatchedDurationStateRef.current /
        videoTotalDurationStateRef.current) *
      100
    ).toString();

    if (props.initiative_id && props.section_id && props.question_id) {
      console.log("Explanation Event 1: ", props);
  
      eventBus.dispatch("USER_EVENT", {
        event: "EXPLANATION_ATTEMPT",
        details: {
          video_duration: videoTotalDurationStateRef.current,
          video_watched_perc: parseInt(watchPerc),
          attempt_time: 0,
          unit_id: props.initiative_id,
          section_id: props.section_id,
          question_id: props.question_id,
        },
      });
      removeSubscription();
    }

  };

  const removeSubscription = () => {
    // console.log("Removing event subscription 1");
    eventBus.remove("EXPLANATION_WATCHED", videoSubscription);
  };

  useEffect(() => {
    // console.log("Setting event subscription 1");
    eventBus.on("EXPLANATION_WATCHED", videoSubscription);

    if(isVideoGif()) {
      setTimeout(() => {
        props.showNextBlock();
      }, 2000);
    }

    return removeSubscription;
  }, []);

  const controls: any = {
    controls: true,
  };

  const isVideoGif = () => {
    return props.block.data && props.block.data.autoplay;
  }

  if(isVideoGif()) {
    delete controls.controls;
    controls.loop = true;
    controls.autoplay = true;
    controls.muted = true;
  }

  let source = "";

  if(props.block.data.url) {
    source = props.block.data.url;
  }

  if(props.block.data.file?.url) {
    source = props.block.data.file.url;
  }

  return (
    <motion.video
      {...controls}
      autoPlay={props.block.data.autoplay}
      muted={props.block.data.autoplay}
      id={`explanation-video-${videoId}`}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      initial={{ opacity: 0, y: 200, scale: 0.8 }}
      transition={{ duration: 1 }}
      onAnimationComplete={() => {
        if(isVideoGif()) {
          return false;
        }
        const firstVideo = document.querySelector(
          `#explanation-video-${videoId}`
        );
        if (firstVideo) {
          const showContinueAndCancelListeners = () => {
            if (!isNextBlockCalled) {
              setNextBlockCalled(true);
              props.showNextBlock();
            }
            setVideoCompleted(true);
            // firstVideo.removeEventListener("timeupdate", onVideoProgress);
            // firstVideo.removeEventListener("pause", onVideoPause);
          };
          const onVideoProgress = (a: any) => {
            if(props.onVideoStart && !isVideoGif()) {
              props.onVideoStart();
            }

            if(videoCompleted) {
              return false;
            }

            const duration = (firstVideo as any).duration;
            const currentTime = (firstVideo as any).currentTime;

            // console.log("Video Progress: ", duration, currentTime);

            setVideoWatchedDuration(parseInt(currentTime));
            videoWatchedDurationStateRef.current = parseInt(currentTime);
            setVideoTotalDuration(duration);
            videoTotalDurationStateRef.current = duration;

            if (currentTime > duration - 1) {
              showContinueAndCancelListeners();
            }

            if(currentTime > 3 && props.mode === "QUICKFLOW") {
              showContinueAndCancelListeners();
            }
          };

          // console.log("Set animated video listener");

          firstVideo.addEventListener("timeupdate", onVideoProgress);
          const onVideoPause = () => {
            if(props.onVideoPause && !isVideoGif()) {
              props.onVideoPause();
            }
            showContinueAndCancelListeners();
          };
          firstVideo.addEventListener("pause", onVideoPause);
        } else {
          if (!isNextBlockCalled) {
            setNextBlockCalled(true);
            props.showNextBlock();
          }
        }
        // console.log("Video animation complete");
        // props.showNextBlock();
      }}
      style={{ width: "100%" }}
      src={source}
      playsInline={true}
    />
  );
};
