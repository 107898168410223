import React from "react";
import { useHistory } from "react-router-dom";
import { CustomRefdocModal } from "./custom-refdoc-modal";

export const CustomRefdocNoHeader = (props: any) => {
  const document_id = +(props as any).match.params.id;
  const history = useHistory();

  return (
    <div className="flex overflow-auto">
        <CustomRefdocModal hideClose={true} hideShare={true} closePreview={() => history.replace("/home") } refDocId={document_id}  />
    </div>
  )
};
