import React from "react";

import qf from "./images/qf.png";
import mdf from "./images/mdf.png";
import play from "./images/play.png";

type iUserMessage = {
    text: string;
}

const message: iUserMessage = {
    text: "How to measure a window for blind installation?",
}

export const AssistantChatMessageUser = () => {
    return (
        <div className="text-base ml-10">
            <div className="bg-blue-600 text-white p-4 rounded-lg">
                {message.text}
            </div>
        </div>
    )
}