import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReferenceDocumentEntity, UnitReviewEntity } from "../../generated/graphql";
// import history from "../../history";
import { FadeIn } from "../fade-in/fade-in";
import { MstrButton } from "../mstr-button/mstr-button";
import { RefdocItem } from "../refdoc-item/refdoc-item";
import { StaggerList } from "../stagger-list/stagger-list";
import { TypeText } from "../type-text/type-text";
import "./closing-sequence.scss";
import { ThumbsDown } from "./icons/thumbs-down";
import { ThumbsUp } from "./icons/thumbs-up";
import { AnimatePresence, motion } from "framer-motion";

const SAVE_INITIATIVE_FEEDBACK = gql`
  mutation SaveInitiativeFeedbackReview($initiative_id: Int!, $rating: String!, $comment: String) {
    saveInitiativeFeedbackReview(initiative_id: $initiative_id, rating: $rating, comment: $comment) {
      id
      unit
      user
      rating
      comment
    }
  }
`;

export const thumbVariants = {
  normal: {
    width: 50,
  },
  elastic: {
    width: 70,
    transition: {
      duration: 0.4,
    },
  },
};

interface Props {
  initiativeId: Number;
}

const GET_INITIATIVE_DOCS = gql`
  query GetReferenceDocsForInitiative($initiative_id: Int!) {
    getReferenceDocsForInitiative(initiative_id: $initiative_id) {
      id
      document_name
      url
      reftype
    }
  }
`;

interface ReferenceDocumentsData {
  getReferenceDocsForInitiative: ReferenceDocumentEntity[];
}

interface InitiativeFeedbackData {
  saveInitiativeFeedbackReview: UnitReviewEntity;
}

export const ClosingSequence = (props: Props) => {

  const history = useHistory();
  const [isTextTyped, setTextTyped] = useState(false);
  const [isRefListShown, setRefListShown] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showFeedbackButtons, setShowFeedbackButtons] = useState(false);
  const [thumbSelected, setThumbSelected] = useState(""); // THUMBS-UP/THUMBS-DOWN
  const [feedbackComment, setFeedbackComment] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [saveInitiativeFeedback] = useMutation<InitiativeFeedbackData>(
    SAVE_INITIATIVE_FEEDBACK,
    {
      update: (cache, response) => {
        console.log("Got Unit Faadbeck response: ", response);
      },
    }
  );
  const { data, error, loading } = useQuery<ReferenceDocumentsData>(
    GET_INITIATIVE_DOCS,
    {
      variables: { initiative_id: props.initiativeId },
    }
  );
  if (loading || !data) {
    return <div></div>;
  }
  return (
    <div className="closing-sequence">
      <h2>
        <TypeText
          onComplete={() => {
            setTextTyped(true);
          }}
        >
          You have completed this Initiative. Here are some documents for you to
          go through:
        </TypeText>
      </h2>
      {isTextTyped && (
        <div className="refdoc-list">
          <StaggerList
            onAnimationComplete={() => {
              setRefListShown(true);
            }}
            list={data?.getReferenceDocsForInitiative.map((doc) => (
              <RefdocItem id={doc.id} name={doc.document_name} url={doc.url} type={doc.reftype} />
            ))}
          />
        </div>
      )}
      {isRefListShown && !showFeedback && (
        <FadeIn>
          <div>
            <br />
            <br />
            <MstrButton
              text="Thanks"
              onClick={() => {
                console.log("Done with the ref docs, navigating to feedback screen");
                history.push(`/initiativefeedback/${props.initiativeId}`);
              }}
            ></MstrButton>
          </div>
        </FadeIn>
      )}
      <br />
      <br />
      {showFeedback && (
        <div>
          <TypeText onComplete={() => setShowFeedbackButtons(true)}>
            How was your experience with this initiative?
          </TypeText>
        </div>
      )}
      <br />
      {showFeedbackButtons && (
        <div className="feedback-icons">
          <FadeIn>
            <motion.div
              variants={thumbVariants}
              initial="normal"
              // animate={thumbSelected === "THUMBS-UP" ? "elastic" : "normal"}
              exit="hidden"
              onClick={() => setThumbSelected("THUMBS-UP")}
              className="thumbs-icon"
            >
              <ThumbsUp isSelected={thumbSelected === "THUMBS-UP"} />
            </motion.div>
          </FadeIn>
          <FadeIn>
            <motion.div
              variants={thumbVariants}
              initial="normal"
              // animate={thumbSelected === "THUMBS-DOWN" ? "elastic" : "normal"}
              exit="hidden"
              onClick={() => setThumbSelected("THUMBS-DOWN")}
              className="thumbs-icon"
            >
              <ThumbsDown isSelected={thumbSelected === "THUMBS-DOWN"} />
            </motion.div>
          </FadeIn>
        </div>
      )}
      {thumbSelected && (
        <motion.div animate={{ height: "auto" }} initial={{ height: 0 }}>
          <FadeIn
            animateHeight={true}
            // onComplete={() => setTextFieldShown(true)}
          >
            <textarea
              rows={5}
              value={feedbackComment}
              onChange={(e) => setFeedbackComment(e.target.value)}
              placeholder="Please share feedback here!"
              className="feedback-text rounded-md border border-gray-400"
            />
            {!feedbackSubmitted && (
              <div style={{ marginBottom: 100, marginTop: 20 }}>
                <MstrButton
                  text="Submit"
                  onClick={() => {
                    saveInitiativeFeedback({
                      variables: {
                        initiative_id: props.initiativeId,
                        comment: feedbackComment,
                        rating: thumbSelected,
                      },
                    });
                    setFeedbackSubmitted(true);
                  }}
                ></MstrButton>
              </div>
            )}
          </FadeIn>
        </motion.div>
      )}
      {feedbackSubmitted && (
        <div className="mt-3">
          <TypeText
            onComplete={() => {
              setTimeout(() => history.push(`/home`), 3000);
            }}
          >
            Thank you for your feedback.
          </TypeText>
        </div>
      )}
      {/* {isRefListShown && (
        
      )} */}
    </div>
  );
};
