import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import IWCEDone from "./iwce-done";
import classNames from "classnames";
import { CheckIcon } from "@heroicons/react/outline";
import { UserSelfRegisterDto } from "../../generated/graphql";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { NavLink } from "react-router-dom";

const USER_REGISTER = gql`
    mutation DemoUserRegister(
        $first_name: String!,
        $last_name: String!,
        $email: String!,
        $company_name: String!,
        $city: String!,
        $state: String!,
        $zip: String!,
        $role: String!
    ) {
      demoUserRegister(
          first_name: $first_name,
          last_name: $last_name,
          email: $email,
          company_name: $company_name,
          city: $city,
          state: $state,
          zip: $zip,
          role: $role
      ) {
        success
        message
      }
    }
`;

const CAN_REGISTER = gql`
    query CanRegisterUsers {
        canRegisterUsers
    }
`;

interface SelfRegisterData {
    demoUserRegister: UserSelfRegisterDto;
}

const ROLES = [
    "Workroom",
    "Installer",
    "Designer",
    "Other",
]

interface iCanRegisterData {
    canRegisterUsers: boolean;
}

export default function IWCE() {

    const TOTAL_STEPS = 3;
    const [currentStep, setCurrentStep] = useState(0);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [role, setRole] = useState(ROLES[0]);

    const [response, setResponse] = useState<UserSelfRegisterDto>({
        message: "Not started",
        success: false,
        userId: -1,
    });

    const next = () => {
        if (currentStep === TOTAL_STEPS - 1) {
            return false;
        }
        setCurrentStep(currentStep + 1);
    }

    const previous = () => {
        if (currentStep === 0) {
            return false;
        }
        setCurrentStep(currentStep - 1);
    }

    const [loading, setLoading] = useState(false);

    const [showDone, setShowDone] = useState(false);

    const { data, error, loading: canRegisterLoading } = useQuery<iCanRegisterData>(CAN_REGISTER);

    const [registerNewUser] = useMutation<SelfRegisterData>(USER_REGISTER, {
        update: (cache, response) => {
            if (response.data) {
                const { message, success } = response.data.demoUserRegister;
                setResponse(response.data.demoUserRegister);
                setShowDone(true);
                setLoading(false);

                if (success) {
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setCompany("");
                    setRole("");
                    setCity("");
                    setState("");
                    setZip("");
                    setCurrentStep(0);
                }
            } else {

            }

        },
        onError: (error) => {
            console.log("Error: ", error);
        }
    });

    const isFirstNameValid = () => {
        if (firstName.trim().length > 1) {
            return true;
        }
        return false;
    }

    const isLastNameValid = () => {
        if (lastName.trim().length > 1) {
            return true;
        }
        return false;
    }

    const isEmailValid = () => {
        if (email.trim().length > 1) {
            return true;
        }
        return false;
    }

    const isCompanyValid = () => {
        if (company.trim().length > 1) {
            return true;
        }
        return false;
    }

    const isCityValid = () => {
        if (city.trim().length > 1) {
            return true;
        }
        return false;
    }

    const isStateValid = () => {
        if (state.trim().length > 1) {
            return true;
        }
        return false;
    }

    const isZipValid = () => {
        if (zip.trim().length > 1) {
            return true;
        }
        return false;
    }

    const shouldShowNext = () => {
        if (currentStep === 0) {
            if (isFirstNameValid() && isLastNameValid() && isEmailValid()) {
                return true;
            }
        }

        if (currentStep === 1) {
            if (isCompanyValid()) {
                return true;
            }
        }

        if (currentStep === 2) {
            if (isCityValid() && isStateValid() && isZipValid()) {
                return true;
            }
        }

        return false;
    }

    if (canRegisterLoading) {
        return <FullScreenLoader />
    }

    // if (data && data.canRegisterUsers === false) {
    //     return (
    //         <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 mx-auto">
    //             <div className="text-center">
    //                 <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Not allowed</h1>
    //                 <p className="mt-6 text-base leading-7 text-gray-600">Sorry, you don't have appropriate permission to perform this action</p>
    //                 <div className="mt-10 flex items-center justify-center gap-x-6">
    //                     <NavLink
    //                         to="home"
    //                         className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    //                     >
    //                         Go back home
    //                     </NavLink>
    //                 </div>
    //             </div>
    //         </main>
    //     )
    // }

    return (
        <div className="md:px-12 w-full sm:bg-gray-100">
            <div className="max-w-7xl mx-auto">
                <div className="fixed bg-gray-100 z-10 p-4 w-full left-0 right-0 flex top-0 items-center logo-icon text-xl sm:text-2xl" style={{ color: "#055b7f" }}>
                    MINDSTRENGTH
                    <div className="px-2 text-lg lg:px-3" style={{
                        fontFamily: "'Allura', cursive",
                    }}>for</div>
                    <img alt="Rollease" src="https://www.rolleaseacmeda.com/app_themes/landing/images/rolleaseacmeda.png" className="h-6 sm:h-6" />
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-5">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">IWCE Registration</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Let's get you started!</p>
                    </div>

                    <div className="bg-white ring-gray-200 sm:rounded-xl md:col-span-4 pt-6">
                        <nav aria-label="Progress" className="flex justify-center pb-6">
                            <ol role="list" className="flex items-center">
                                {Array(TOTAL_STEPS).fill(0).map((step, stepIdx) => (
                                    <li key={stepIdx} className={classNames(stepIdx !== TOTAL_STEPS - 1 ? 'pr-8 sm:pr-20' : '', 'relative block ml-0')}>
                                        {stepIdx < currentStep ? (
                                            <>
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="h-0.5 w-full bg-blue-600" />
                                                </div>
                                                <div
                                                    className="relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900"
                                                >
                                                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                    <span className="sr-only">{step.name}</span>
                                                </div>
                                            </>
                                        ) : stepIdx === currentStep ? (
                                            <>
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="h-0.5 w-full bg-gray-200" />
                                                </div>
                                                <div
                                                    className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-800 bg-white"
                                                    aria-current="step"
                                                >
                                                    <span className="h-2.5 w-2.5 rounded-full bg-blue-600" aria-hidden="true" />
                                                    <span className="sr-only">{step.name}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="h-0.5 w-full bg-gray-200" />
                                                </div>
                                                <div
                                                    className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                                                >
                                                    <span
                                                        className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="sr-only">{step.name}</span>
                                                </div>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </nav>
                        <div className="px-4 py-6 sm:p-8 sm:pt-0">
                            <form onSubmit={(e) => {

                                e.preventDefault();

                                if (!shouldShowNext()) {
                                    return false;
                                }

                                if (currentStep === TOTAL_STEPS - 1) {
                                    setLoading(true);
                                    registerNewUser({
                                        variables: {
                                            first_name: firstName,
                                            last_name: lastName,
                                            email,
                                            company_name: company,
                                            city,
                                            state,
                                            zip,
                                            role,
                                        }
                                    })
                                } else {
                                    next();
                                }
                            }} action="#" className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                {/* STEP 1: FIRST NAME */}
                                <div className={classNames("sm:col-span-3", currentStep === 0 ? "block" : "hidden")}>
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 0}
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* STEP 1: LAST NAME */}
                                <div className={classNames("sm:col-span-3", currentStep === 0 ? "block" : "hidden")}>
                                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Last name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 0}
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* STEP 1: EMAIL */}
                                <div className={classNames("sm:col-span-4", currentStep === 0 ? "block" : "hidden")}>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 0}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* STEP 2: COMPANY */}
                                <div className={classNames("col-span-4", currentStep === 1 ? "block" : "hidden")}>
                                    <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                                        Company
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 1}
                                            value={company}
                                            onChange={(e) => setCompany(e.target.value)}
                                            type="text"
                                            name="company"
                                            id="company"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* STEP 2: ROLE */}
                                <div className={classNames("col-span-4", currentStep === 1 ? "block" : "hidden")}>
                                    <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                                        Role
                                    </label>
                                    <select
                                        id="role"
                                        name="role"
                                        // value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        required={currentStep === 1}
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        defaultValue="Canada"
                                    >
                                        <option value=''></option>
                                        {ROLES.map(role => (<option key={role}>{role}</option>))}
                                    </select>
                                </div>

                                {/* STEP 3: CITY */}
                                <div className={classNames("sm:col-span-2 sm:col-start-1", currentStep === 2 ? "block" : "hidden")}>
                                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 2}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            type="text"
                                            name="city"
                                            id="city"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* STEP 3: STATE */}
                                <div className={classNames("sm:col-span-2", currentStep === 2 ? "block" : "hidden")}>
                                    <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                        State
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 2}
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            type="text"
                                            name="region"
                                            id="region"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* STEP 3: ZIP */}
                                <div className={classNames("sm:col-span-2", currentStep === 2 ? "block" : "hidden")}>
                                    <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                                        ZIP code
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            required={currentStep === 2}
                                            value={zip}
                                            onChange={(e) => setZip(e.target.value)}
                                            type="text"
                                            name="postal-code"
                                            id="postal-code"
                                            autoComplete="one-time-code"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="flex w-full justify-between gap-x-6 border-gray-900/10 py-4 sm:col-span-3">
                                    <button
                                        type="button"
                                        onClick={() => previous()}
                                        className="rounded-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        // disabled={loading || !shouldShowNext()}
                                        type="submit"
                                        // style={{
                                        //     background: !shouldShowNext() ? "gray" : "",
                                        // }}
                                        className="rounded-md bg-blue-600 px-12 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        {currentStep === TOTAL_STEPS - 1 ? "Submit" : "Next"}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <IWCEDone success={response.success} message={response.message} open={showDone} setOpen={setShowDone} />
            </div>
        </div>
    )
}