import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ShowAfter } from "../../components/show-after/show-after";
import { StaggerList } from "../../components/stagger-list/stagger-list";
import { FadeIn } from "../../components/fade-in/fade-in";

interface Props {
  hideMenu: Function;
}

export const Menu = (props: Props) => {
  return (
    <motion.div
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 1, easings: "easeInOut" }}
      className="menu-container"
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        background: "#d5d5d5",
      }}
    >
      <motion.div
        className="menu"
        transition={{ duration: 1, easings: "easeInOut" }}
        initial={{ opacity: 0, height: 0, width: 0 }}
        animate={{ opacity: 1, height: 300, width: 300 }}
        exit={{ opacity: 0, height: 0 }}
        style={{
          height: 300,
          width: 300,
          background: "#89c5e1",
          padding: 15,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="menu-header"
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <FadeIn>
            <div
              onClick={() => {
                console.log("hider clicked");
                props.hideMenu();
              }}
            >
              <img
                style={{ width: 30, cursor: "pointer" }}
                src="https://upload.wikimedia.org/wikipedia/en/thumb/6/61/Cross_icon_%28white%29.svg/1024px-Cross_icon_%28white%29.svg.png"
              />
            </div>
          </FadeIn>
        </div>
        <ShowAfter delay={1400}>
          <StaggerList
            list={[
              {
                name: "Logout",
                icon: "https://static.thenounproject.com/png/205237-200.png",
              },
              {
                name: "Profile",
                icon:
                  "https://ddo0fzhfvians.cloudfront.net/uploads/icons/png/7104608081548233620-512.png",
              },
              {
                name: "Adjust Mentor",
                icon: "https://img.icons8.com/ios/452/settings.png",
              },
            ].map((menu) => {
              return (
                <div>
                  <div
                    style={{
                      background: "white",
                      padding: 10,
                      borderRadius: 5,
                      width: 280,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <img style={{ width: 20 }} src={menu.icon} />
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        paddingLeft: 10,
                      }}
                    >
                      {menu.name}
                    </div>
                  </div>
                  <br />
                </div>
              );
            })}
          />
        </ShowAfter>
      </motion.div>
    </motion.div>
    // </AnimatePresence>
  );
};
