import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.scss";
// import { AnimatedContent } from "./components/animated-content/animated-content";
// import { AnimatedScreen } from "./screens/animated/animated";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { ChatWrapper } from "./components/header/screens/chat-wrapper/chat-wrapper";
import { ConversationDetailsRouterWrapper } from "./components/header/screens/conversations/components/conversation-details/conversation-details-router-wrapper";
import { InitiateConversation } from "./components/header/screens/conversations/components/initiate-conversation/initiate-conversation";
import { ConversationScreen } from "./components/header/screens/conversations/conversations";
import { NotificationsScreen } from "./components/header/screens/notifications/notifications";
import { UserEvents } from "./components/user-events/user-events";
import { ForgotLearnerPasswordResetScreen } from "./screens/forgot-password-reset/forgot-password-reset";
import { ForgotPasswordScreen } from "./screens/forgot-password/forgot-password";
import { HomeScreen } from "./screens/home/home";
import { InitiativeScreen } from "./screens/initiative/initiative.screen";
import { LoginScreen } from "./screens/login/login";
import { Menu } from "./screens/menu/menu";
import { PasswordResetScreen } from "./screens/password-reset/password-reset";
import { QuestionScreen } from "./screens/question/question.screen";
import { RefdocPreviewScreen } from "./screens/refdoc-preview/refdoc-preview";
import { SectionQuestions } from "./screens/section-questions/section-questions";
import { SectionScreen } from "./screens/section/section.screen";

import classNames from "classnames";
import { MsMessenger } from "./components/header/common/messenger/messenger";
import { InitiateConversationWrapper } from "./components/header/screens/initiate-conversation-wrapper/initiate-conversation-wrapper";
import { UMHeaderDark } from "./components/header/umheader_dark";
import { AutoLoginScreen } from "./screens/auto-login/auto-login";
import { AutoLoginInitiativeScreen } from "./screens/auto-login/auto-login-initiative";
import { CustomRefdoc } from "./screens/custom-refdoc/custom-refdoc";
import { FirstLoginScreen } from "./screens/first-login/first-login";
import { InitiativeFeedback2 } from "./screens/initiative-feedback-2/initiative-feedback-2";
import { InitiativeIntroScreen } from "./screens/initiative-intro/initiative-intro.screen";
import { ResetPasswordScreen } from "./screens/login/reset-password";
import Preferences from "./screens/preferences/preferences";
import { QuickFlow } from "./screens/quickflow/quickflow";
import { SetPasswordScreen } from "./screens/set-password/set-password";
import { Slide1 } from "./slides/slide1";
import { Slide2 } from "./slides/slide2";
import { Slide3 } from "./slides/slide3/slide3";
import { Slide4 } from "./slides/slide4/slide4";
import { Slide5 } from "./slides/slide5/slide5";
import IWCE from "./screens/iwce/iwce";
import { AutoLoginQFScreen } from "./screens/auto-login/auto-login-qf";
import { QuickFlowOpen } from "./screens/quickflow/quickflow-open";
import { UMHeaderDarkOpen } from "./components/header/umheader_dark-open";
import { CustomRefdocOpen } from "./screens/custom-refdoc/custom-refdoc-share";
import { AssistantPage } from "./components/assistant/assistant-page";
import { AssistantChat } from "./components/assistant/assistant-chat";
import LinearFlowElementPreview from "./screens/linear-flow-element-preview/linear-flow-element-preview";
import { CustomRefdocNoHeader } from "./screens/custom-refdoc/custom-refdoc-no-header";
import { QuickFlowNoHeader } from "./screens/quickflow/quickflow-no-header";
import { UMHeaderDarkOpenNoLogout } from "./components/header/umheader_dark-open-no-logout";
import { InitiativeIntroPreviewScreen } from "./screens/initiative-intro/initiative-intro-preview.screen";


function App() {
  const [isMenuShown, setShowMenu] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const shouldShowOpenHeader = (
    currentPath.indexOf("quickflow-share") > -1 ||
    currentPath.indexOf("mdf-share") > -1
  );

  const shouldShowOpenHeaderNoLogout = (
    currentPath.indexOf("lf-preview") > -1 ||
    currentPath.indexOf("custom-refdoc-no-header") > -1 ||
    currentPath.indexOf("initiative-intro-preview") > -1 ||
    currentPath.indexOf("quickflow-no-header") > -1
  );

  useEffect(() => {
    if (
      currentPath.indexOf("login") === -1 &&
      currentPath.indexOf("iwce") === -1 &&
      currentPath.indexOf("password") === -1 &&
      currentPath.indexOf("set-password") === -1 &&
      currentPath.indexOf("quickflow-share") === -1 &&
      currentPath.indexOf("mdf-share") === -1 &&
      currentPath.indexOf("lf-preview") === -1 &&
      currentPath.indexOf("custom-refdoc-no-header") === -1 &&
      currentPath.indexOf("quickflow-no-header") === -1 &&
      currentPath.indexOf("initiative-intro-preview") === -1 &&
      // location.search.indexOf("hideHeader") === -1 &&
      currentPath.indexOf("token") === -1
    ) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  }, [location]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        // position: 'relative',
        overflow: "hidden",
      }}
    >
      {showHeader && <UMHeaderDark />}

      {shouldShowOpenHeader && <UMHeaderDarkOpen />}

      {shouldShowOpenHeaderNoLogout && <UMHeaderDarkOpenNoLogout />}

      {/* {location.pathname.indexOf("login") > -1 ? null : <Header />} */}
      <div
        className={classNames("App pt-16")}
        style={{
          width: !showHeader
            ? "100%"
            : window.innerWidth > 600
            ? 600
            : window.innerWidth,
          // overflow: "auto",
        }}
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/question/:id" component={QuestionScreen} />
          <Route
            path="/initiative/:initiativeid/section/:sectionid/questions"
            component={SectionQuestions}
          />
          <Route
            path="/lf-preview/element/:id"
            component={LinearFlowElementPreview}
          />
          <Route
            path="/initiative/:initiativeid/section/:sectionid/sort/:sortid"
            component={SectionScreen}
          />
          <Route path="/token/:id/initiative/:initiativeid" component={AutoLoginInitiativeScreen} />
          <Route path="/token/:id/qf/:documentid" component={AutoLoginQFScreen} />
          <Route path="/token/:id" component={AutoLoginScreen} />
          <Route path="/first-login/:id" component={FirstLoginScreen} />
          <Route
            path="/forgotpassword/:token"
            component={ResetPasswordScreen}
          />
          <Route path="/initiative/:id" component={InitiativeScreen} />

          <Route path="/initiative-intro/:id" component={InitiativeIntroScreen} />
          <Route path="/initiative-intro-preview/:id" component={InitiativeIntroPreviewScreen} />

          <Route path="/section/:id" component={SectionScreen} />
          <Route
            path="/refdoc/:id/details/:url"
            component={RefdocPreviewScreen}
          />
          <Route path="/login" component={LoginScreen} />
          <Route path="/iwce" component={IWCE} />
          <Route path="/password-reset" component={PasswordResetScreen} />
          <Route
            path="/forgot-password/:token"
            component={ForgotLearnerPasswordResetScreen}
          />
          <Route
              path="/auto-login/:token"
              component={AutoLoginScreen}
          />
          <Route path="/set-password/:token" component={SetPasswordScreen} />
          {/* 08afcb3f-9234-4f55-b8f7-51c911cf654a */}
          <Route path="/forgot-password" component={ForgotPasswordScreen} />
          <Route path="/notifications" component={NotificationsScreen} />
          <Route
            path="/conversations/:id"
            component={ConversationDetailsRouterWrapper}
          />
          <Route
            path="/initiativefeedback/:id"
            component={InitiativeFeedback2}
          />

          <Route
            path="/quickflow/:id"
            component={QuickFlow}
          />

          <Route
            path="/quickflow-no-header/:id"
            component={QuickFlowNoHeader}
          />

          <Route
            path="/quickflow-share/:id"
            component={QuickFlowOpen}
          />
          <Route
            path="/settings"
            component={Preferences}
          />

          <Route
            path="/assist"
            component={AssistantPage}
          />

          <Route
            path="/assist-chat"
            component={AssistantChat}
          />

          <Route
            path="/custom-refdoc/:id"
            component={CustomRefdoc}
          />

          <Route
            path="/custom-refdoc-no-header/:id"
            component={CustomRefdocNoHeader}
          />

          <Route
            path="/mdf-share/:id"
            component={CustomRefdocOpen}
          />

          <Route path="/slide1" component={Slide1} />
          <Route path="/slide2" component={Slide2} />
          <Route path="/slide3" component={Slide3} />
          <Route path="/slide4" component={Slide4} />
          <Route path="/slide5" component={Slide5} />

          <Route path="/conversations" component={ConversationScreen} />
          <Route path="/messenger" component={MsMessenger} />
          <Route
            path="/initiate-conversation"
            component={InitiateConversation}
          />
          <AnimateSharedLayout>
            <Route path="/home" component={HomeScreen} />
          </AnimateSharedLayout>
          {/* <Route path="/animated" component={AnimatedContent} /> */}
        </Switch>
        <ChatWrapper />
        <InitiateConversationWrapper />
        <AnimatePresence>
          {isMenuShown && (
            <Menu
              hideMenu={() => {
                console.log("hide called");
                setShowMenu(false);
              }}
            />
          )}
        </AnimatePresence>
      </div>
      <UserEvents />
    </div>
  );
}

export default App;
