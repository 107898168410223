import React from 'react';
import { ConversationList } from './components/conversation-list/conversation-list';
import './conversations.scss';

interface Props {
    closeConversationListDrawer?: Function;
    initiative_id: number;
    buttonRef?: any;
}

export const ConversationScreen = (props: Props) => {
    return (
        <div className="conversation-screen">
            <ConversationList buttonRef={props.buttonRef} initiative_id={props.initiative_id} closeConversationListDrawer={props.closeConversationListDrawer} />
        </div>
    )
}