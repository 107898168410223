import React from "react";
import { AssistantLoader } from "./assistant-loader";

const EXAMPLES = [
    "Where can I learn more about Bypass and Butt shades?",
    "What are the steps to measuring a window size properly?",
    "How to setup TaHoma app?"
]

export const AssistantExamples = () => {
    return (
        <div className="w-full space-y-6 mt-10 px-4">
            {EXAMPLES.map(example => (
                <div className="bg-blue-500 text-white rounded-lg p-3 text-base">
                    {example}
                </div>
            ))}
        </div>
    )
}