import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { eventBus } from "../../event-bus/event-bus";
import { AnimatedContent } from "../animated-content/animated-content";
import { FadeIn } from "../fade-in/fade-in";
import { MstrButton } from "../mstr-button/mstr-button";
import { TypeText } from "../type-text/type-text";
import "./explanation.scss";
import TTS from "../tts/tts";

interface Props {
  explanation: Object;
  attemptFeedbackMessage: string;
  onComplete: Function;
  shouldShowExplanation: boolean;
  isCorrect: string;
  uuid: any;
  initiative_id: number;
  section_id: number;
  question_id: number;
  userListenedToElementSpeech?: boolean;
  isPreview?: boolean;
}

export const QuestionExplanation = (props: Props) => {
  const [isFeedbackMessageTyped, setFeedbackMessageTyped] = useState(false);
  const [isContinueButtonShown, showContinueButton] = useState(false);
  const [paused, setPaused] = useState(false);

  const [explanationStartTime, setExplanationStartTime] = useState<Date>(
    new Date()
  );

  // const manageGotItButton = () => {
  //   const firstVideo = document.querySelector(
  //     `#explanation-${props.uuid} video`
  //   );
  //   if (firstVideo) {
  //     const showContinueAndCancelListeners = () => {
  //       showContinueButton(true);
  //       firstVideo.removeEventListener("timeupdate", onVideoProgress);
  //       firstVideo.removeEventListener("pause", onVideoPause);
  //     };
  //     const onVideoProgress = (a: any) => {
  //       const duration = (firstVideo as any).duration;
  //       const currentTime = (firstVideo as any).currentTime;
  //       if (currentTime > duration - 10) {
  //         showContinueAndCancelListeners();
  //       }
  //     };

  //     firstVideo.addEventListener("timeupdate", onVideoProgress);
  //     const onVideoPause = () => {
  //       showContinueAndCancelListeners();
  //     };
  //     firstVideo.addEventListener("pause", onVideoPause);
  //   } else {
  //     showContinueButton(true);
  //   }
  // };

  if (!props.shouldShowExplanation) {
    return (
      <div>
        <div className="question-explanation" id={`explanation-${props.uuid}`}>
          <TypeText
            delay={1000}
            onComplete={() => {
              props.onComplete();
            }}
          >
            {props.isCorrect === "YES" ? (
              props.attemptFeedbackMessage
            ) : (
              <div>&nbsp;</div>
            )}
          </TypeText>
          <br />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="question-explanation" id={`explanation-${props.uuid}`}>
      
        {props.isPreview && <TypeText
          delay={1000}
          onComplete={() => {
            setFeedbackMessageTyped(true);
          }}
          isPreview={true}
        >
          Here is what we should focus on.
        </TypeText>}

        <TypeText
          delay={1000}
          onComplete={() => {
            setFeedbackMessageTyped(true);
          }}
        >
          {props.attemptFeedbackMessage}
        </TypeText>
        <br />
        {isFeedbackMessageTyped && (
          <>
            {props.userListenedToElementSpeech && (
              <TTS pause={paused} className="float-left pr-3" id={props.question_id} key={props.question_id} connector={props.attemptFeedbackMessage} url="/api2/question/speech/explain" />
            )}
            <AnimatedContent
              isPreview={props.isPreview}
              initiative_id={props.initiative_id}
              section_id={props.section_id}
              question_id={props.question_id}
              mode="EXPLANATION"
              pauseTextOnPeriod={true}
              onComplete={() => {
                const now = new Date();
                showContinueButton(true);
              }}
              data={props.explanation}
            />
          </>
        )}
      </div>
      {(isContinueButtonShown || props.isPreview) && (
        <FadeIn animateHeight={true}>
          <br /> <br />
          <MstrButton
            text="Got it!"
            onClick={() => {
              setPaused(true);
              showContinueButton(false);
              const now = new Date();
              console.log(
                "Time for explanation: ",
                now.getTime() - explanationStartTime.getTime()
              );

              const explanationHasVideo = (props.explanation as any).blocks.filter(
                (bl: any) => bl.type === "video"
              ).length;
              console.log(111111112);
              if (explanationHasVideo) {
                eventBus.dispatch("EXPLANATION_WATCHED", {});
              } else {
                console.log("Explanation Event 2: ", props);
                eventBus.dispatch("USER_EVENT", {
                  event: "EXPLANATION_ATTEMPT",
                  details: {
                    video_duration: 0,
                    video_watched_perc: 0,
                    attempt_time:
                      now.getTime() - explanationStartTime.getTime(),
                    unit_id: props.initiative_id,
                    section_id: props.section_id,
                    question_id: props.question_id,
                  },
                });
              }
              props.onComplete();
            }}
            disabled={props.isPreview}
          />
          <br /> <br />
        </FadeIn>
      )}
    </div>
  );
};
