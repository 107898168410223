import { CheckCircleIcon, PlayIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import AnimatedText from "react-animated-text-content";

const timestamps = [6, 12, 17];

export const Slide1 = () => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      const vid: any = document.getElementById("left");
      const onVideoProgress = (a: any) => {
        const currentTime = (vid as any).currentTime;
        setTime(currentTime);
      };
      if (vid) {
        vid.addEventListener("timeupdate", onVideoProgress);
      }
    }, 1000);
  }, []);

  const iconClick = (timestamp: number) => {
    const vid: any = document.getElementById("left");
    if (vid) {
      vid.currentTime = timestamp;
      if (vid.paused) {
        vid.play();
      }
    }
  };

  return (
    <div
      className="absolute inset-0 z-50 flex"
      style={{
        background: "#bad4ce",
      }}
    >
      <div className="flex-1 flex items-center justify-center">
        <video
          id="left"
          controls
          autoPlay
          className="w-96"
          src="https://d205v7va10brr8.cloudfront.net/9c2af868-70d6-4987-9b8f-02ec8d41fb5b.mov"
        />
      </div>
      <div className="flex-1 bg-gray-200 text-xl flex flex-col justify-around px-10">
        {timestamps.map((timestamp) => (
          <div className="flex">
            <div>
              {time > timestamp && (
                <CheckCircleIcon
                  onClick={() => {
                    iconClick(timestamp);
                  }}
                  className="text-green-500 w-10 h-10 cursor-pointer"
                />
              )}
              {time < timestamp && (
                <PlayIcon
                  onClick={() => {
                    iconClick(timestamp);
                  }}
                  className="text-red-500 w-10 h-10 cursor-pointer"
                />
              )}
            </div>
            <div className="ml-4">
              <div className="text-3xl">Heading</div>
              <div className="text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AnimatedTextExample = () => {
  return (
    <div className="w-96">
      <AnimatedText
        type="words"
        animation={{
          x: "200px",
          y: "-20px",
          scale: 1.1,
          ease: "ease-in-out",
        }}
        animationType="throw"
        interval={0.06}
        duration={0.8}
        tag="p"
        className="animated-paragraph"
        includeWhiteSpaces
        threshold={0.1}
        rootMargin="20%"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </AnimatedText>
    </div>
  );
};
