import React, { useState } from "react";
import { TypeText } from "../../../components/type-text/type-text";
import { gql, useMutation } from "@apollo/client";
import { MstrButton } from "../../../components/mstr-button/mstr-button";
import { IhAssistOutput, ReferenceDocumentEntity } from "../../../generated/graphql";
import { RefdocItem } from "../../../components/refdoc-item/refdoc-item";
import { StaggerList } from "../../../components/stagger-list/stagger-list";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { MsLoader } from "../../../components/header/screens/ms-loader/ms-loader";
import { FadeIn } from "../../../components/fade-in/fade-in";

const GET_ASSIST_DATA = gql`
  mutation IhAssist($message: String!) {
    ihAssist(message: $message) {
        documents {
            id
            document_name
            url
            reftype
        }
        initiative {
            id
            name
        }
        answer
    }
  }
`;

const SELF_ASSIGN_INITIATIVE = gql`
  mutation SelfAssignInitiativeToLearner($initiative_id: Int!) {
    selfAssignInitiativeToLearner(initiative_id: $initiative_id)
  }
`;

type iProps = {
    assignedInitiativeIds: number[];
    refetchInitiatives: () => Promise<void>;
    toggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InlineAssistant = (props: iProps) => {

    const [showTextbox, setShowTextBox] = useState(false);
    const [message, setMessage] = useState("");
    const [answerTyped, setAnswerTyped] = useState(false);
    const [refdocHeadingTyped, setrefdocHeadingTyped] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [showInitiativeDetail, setShowInitiativeDetail] = useState(false);

    const [selfAssignInitiative, { loading: loadingAssignInitiative }] = useMutation(
        SELF_ASSIGN_INITIATIVE,
        {
            update: async (cache, res) => {
                console.log("Got self assign initiative response: ", response);
                await props.refetchInitiatives();
                if (response) {
                    setTimeout(() => {
                        history.push(`/initiative-intro/${response.initiative.id}`);
                    }, 1000);
                }
            },
        }
    );

    const history = useHistory();

    const [response, setResponse] = useState<IhAssistOutput>();

    const [assist, { loading }] = useMutation(GET_ASSIST_DATA, {
        update: (cache, response) => {
            console.log("Assist Response: ", response);
            setShowTextBox(false);
            setResponse(response.data.ihAssist);
            setShowButton(false);
        },
    });

    const isInitiativeAssigned = response && response.initiative && props.assignedInitiativeIds.indexOf(+response.initiative.id) > -1 ? true : false;

    return (
        <div className="pb-4">

            {loadingAssignInitiative && <div className="fixed inset-0 flex items-center justify-center bg-white">
                <MsLoader />
            </div>}

            {!response && <TypeText delay={500} onComplete={() => {
                setShowTextBox(true);
            }}>
                Need help or looking for a document?
            </TypeText>}

            {showTextbox && <motion.textarea
                animate={{ height: "auto" }}
                initial={{
                    height: "1px",
                }}
                transition={{
                    duration: 0.6,
                }}
                onAnimationComplete={() => {
                    setShowButton(true);
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full my-4 outline-none rounded-md"
                autoFocus={true}
                rows={2}
            />}

            {response && <div>
                <div className="text-base">
                    <TypeText onComplete={() => {
                        setAnswerTyped(true);
                    }}>
                        {response.answer}
                    </TypeText>
                </div>

                {response.documents && answerTyped && <div>
                    <TypeText onComplete={() => setrefdocHeadingTyped(true)}>
                        <div className="text-base pt-6">
                            {response.documents.length === 1 ?
                                "Here is a QuickFlow (or document) that will help:" : "Here are some QuickFlows (or documents) that will help:"
                            }
                        </div>
                    </TypeText>

                    {refdocHeadingTyped && <StaggerList
                        onAnimationComplete={() => {
                            setShowInitiativeDetail(true);
                        }}
                        list={response.documents.map((document: ReferenceDocumentEntity) => (
                            <div style={{ paddingLeft: 10 }} key={document.id}>
                                <RefdocItem
                                    refdocCount={{}}
                                    id={document.id}
                                    name={document.document_name}
                                    url={document.url}
                                    type={document.reftype}
                                />
                            </div>
                        ))}
                    />}

                    <br />

                </div>}

                {showInitiativeDetail && <div className="text-base">
                    {<div>
                        <TypeText onComplete={() => {
                            setShowButton(true);
                        }}>
                            To get an even better understanding, try this initiative, <span className="font-bold text-blue-500 cursor-pointer" onClick={() => {
                                const isAlreadyAssigned = props.assignedInitiativeIds.indexOf(+response.initiative.id) > -1;

                                if (isAlreadyAssigned) {
                                    history.push(`/initiative-intro/${response.initiative.id}`);
                                } else {
                                    selfAssignInitiative({
                                        variables: {
                                            initiative_id: +response.initiative.id,
                                        }
                                    })
                                }
                            }}>{response.initiative.name}.</span>
                            <br />
                        </TypeText>
                    </div>}
                </div>}

            </div>}


            {showButton && <FadeIn>
                {!response && <MstrButton disabled={loading} showLoading={loading} text={"Go"} onClick={() => {
                    assist({
                        variables: {
                            message,
                        }
                    })
                }} />}

                {response && <div className="flex space-x-6 mt-6">
                    <MstrButton className="flex-1" text="New Request" onClick={() => {
                        setResponse(undefined);
                        setShowTextBox(false);
                        setMessage("");
                        setAnswerTyped(false);
                        setrefdocHeadingTyped(false);
                        setShowButton(false);
                        setShowInitiativeDetail(false);
                    }} />

                    <MstrButton className="flex-1" text="All Done" onClick={() => {
                        props.toggle(false);
                    }} />
                </div>}
            </FadeIn>}

        </div>
    )
}