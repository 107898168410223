import React from "react";

export const Slideshow3 = () => {
  return (
    <div>
      <img src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/faux-wood-blinds.jpg" />
      <div className="py-6">
        When a space is prone to high levels of moisture, real wood can
        sometimes warp or crack. To avoid this happening in kitchens or
        bathrooms, look to faux wooden blinds. Faux wooden blinds provide water
        resistance, stain resistance and are as easy to clean as real wooden
        blinds. Faux wooden blinds don’t compromise on style either, they’re
        just as fashionable, and functional, as their real wood counterparts.
      </div>
    </div>
  );
};
