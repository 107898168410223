import React from "react";
import { LearnerDetailsOutput } from "../../../generated/graphql";
import yogaImage from "../../../images/corporate_yoga.png";

interface Props {
  details: LearnerDetailsOutput;
}

export const WelcomeMessage = (props: Props) => {
  let name = props.details.first_name;
  // if(props.details.last_name) {
  //   name = name + ' ' + props.details.last_name;
  // }
  return (
    <div className="welcome-message">
      <img alt="Welcome" src={yogaImage} />
      <div className="welcome-text">Welcome, {name}!</div>
    </div>
  );
};
