import React from "react";
import "./assistant-loader.css";

export const AssistantLoader = () => {
    return (
        <div className="relative transform scale-50">
            <div className="text-6xl font-bold text-white absolute top-0 z-50" style={{
                left: "33%",
                top: "33%",
            }}>M</div>
            <div className="msassist-loader">
                <div className="msassist-loader_cube msassist-loader_cube--color"></div>
                <div className="msassist-loader_cube msassist-loader_cube--glowing"></div>
            </div>
        </div>
    )
}