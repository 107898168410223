import { Switch } from "@headlessui/react";
import classNames from "classnames";
import React, { useState } from "react";
import { getFontSize, getHideHeader, setFontSize, setHideHeader } from "../../AppFont";

const Preferences = () => {
  const [size, setSize] = useState(getFontSize());
  const [enabled, setEnabled] = useState(getHideHeader());

  return (
    <div className="p-4">
      <div className="text-base text-blue-400">
        Text size (Sample text below)
      </div>
      <div
        className="mt-2 mb-3"
        style={{
          fontSize: size,
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </div>
      <span className="isolate inline-flex rounded-md shadow-sm">
        <button
          type="button"
          onClick={() => {
            setSize(15);
            setFontSize(15);
          }}
          className={classNames(
            "relative inline-flex items-center rounded-l-md border border-gray-300  px-4 py-2 text-sm font-medium focus:z-30 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
            size === 15 ? "bg-blue-400 text-white" : "bg-white text-gray-700"
          )}
        >
          Small
        </button>
        <button
          type="button"
          onClick={() => {
            setSize(16);
            setFontSize(16);
          }}
          className={classNames(
            "relative -ml-px inline-flex items-center border border-gray-300  px-4 py-2 text-sm font-medium focus:z-30 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
            size === 16 ? "bg-blue-400 text-white" : "bg-white text-gray-700"
          )}
        >
          Normal
        </button>
        <button
          type="button"
          onClick={() => {
            setSize(17);
            setFontSize(17);
          }}
          className={classNames(
            "relative -ml-px inline-flex items-center rounded-r-md border border-gray-300  px-4 py-2 text-sm font-medium focus:z-30 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
            size === 17 ? "bg-blue-400 text-white" : "bg-white text-gray-700"
          )}
        >
          Large
        </button>
      </span>

      {/* <div className="mt-10">
        <Switch.Group as="div" className="flex items-center">
          <Switch.Label as="span" className="mr-3">
            <span className="text-sm font-medium text-gray-900">
              Hide header when going through an Initiative
            </span>
          </Switch.Label>
          <Switch
            checked={enabled}
            onChange={(e) => {
                setEnabled(e);
                setHideHeader(e);
            }}
            className={classNames(
              enabled ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </Switch.Group>
      </div> */}
    </div>
  );
};

export default Preferences;
