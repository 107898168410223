import React from "react";
import { SearchIcon } from "@heroicons/react/solid";

interface Props {
  searchText: string;
  setSearchText: Function;
  placeholder: string;
}

export const SearchField = (props: Props) => {
  return (
    <div className="flex items-center justify-center px-2 py-2 w-full">
      <div className="max-w-lg w-full">
        <label htmlFor="search" className="sr-only">
          Search users
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            onChange={(e) => props.setSearchText(e.target.value)}
            name="search"
            value={props.searchText}
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-300 focus:border-indigo-300 sm:text-sm"
            placeholder={props.placeholder}
            type="search"
          />
        </div>
      </div>
    </div>
  );
};
