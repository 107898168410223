import { OutputBlockData, OutputData } from "@editorjs/editorjs";
import edjsHTML from "editorjs-html";

function customParser(block: OutputBlockData) {
  return `<div> ${block.data.text}</div>`;
}

function videoParser(block: OutputBlockData) {
  const url = block.data.url || block.data.file?.url;
  return `<video class="block" controls src="${url}" />`
}

function delimiterParser(block: OutputBlockData) {
  return `<br />`
}

const edjsParser = edjsHTML({
  paragraph: customParser,
  video: videoParser,
});

export const getHtmlFromBlocks = (data: OutputData) => {
    const html = edjsParser.parse(data);
    return html;
}