import React, { useEffect, useState } from "react";
import { Fragment, LegacyRef, useRef } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChatAlt2Icon, BellIcon } from "@heroicons/react/solid";
import "./umheader_dark.scss";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ConversationScreen } from "./screens/conversations/conversations";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  LearnerDetailsOutput,
  UnreadCountOutput,
} from "../../generated/graphql";
import { UserAvatar } from "./common/user-avatar/user-avatar";
import { MsMessenger } from "./common/messenger/messenger";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { NotificationsScreen } from "./screens/notifications/notifications";
import { BellFilled } from "@ant-design/icons";

const isMobile = window.innerWidth < 550;

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const shouldShowNew = window.location.pathname.match("ih");

const products = [
  {
    name: "Initiative Hub",
    url: "/ih/home",
  },
  {
    name: "Initiative Design Center",
    url: "/design/home",
  },
  {
    name: "SkyView",
    url: "/sv/home",
  },
  {
    name: "User Management",
    url: "/user-management/home",
  },
];

const GET_UNREAD_DATA = gql`
  query GetUnreadCountConversations {
    getUnreadCountConversations {
      unread_conversations
      unread_notifications
    }
    getLearnerDetails {
      id
      first_name
      last_name
      user_id_state
      avatar_url
      login_cds
      login_skyview
      is_admin
    }
  }
`;

const isIdc = window.location.pathname.indexOf("idc") > -1;

const IS_ROLLEASE = window.location.host.indexOf("rollease") > -1;
const IS_BBLIND = window.location.host.indexOf("bblinds") > -1;

interface UnreadData {
  getUnreadCountConversations: UnreadCountOutput;
  getLearnerDetails: LearnerDetailsOutput;
}

export const UMHeaderDark = () => {
  const history = useHistory();
  const client = useApolloClient();
  const [showNotifications, setShowNotifications] = useState(false);
  const buttonRef = useRef() as LegacyRef<HTMLButtonElement>;
  const { loading, error, data, refetch } = useQuery<UnreadData>(
    GET_UNREAD_DATA
  );
  const onNotificationClick = () => {
    if (isMobile) {
      history.push("/notifications");
    } else {
      setShowNotifications(true);
    }
  };

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refetch();
    }, 2000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  let login_cds = false;
  let login_skyview = false;
  let is_admin = false;

  // const token = localStorage.getItem("token");
  // if (token && token.split(".").length) {
  //   const details = JSON.parse(atob(token.split(".")[1]));
  //   console.log({ details });
  //   login_cds = details.login_cds;
  //   login_skyview = details.login_skyview;
  //   is_admin = details.is_admin;
  // }

  if (data?.getLearnerDetails) {
    login_cds = data.getLearnerDetails.login_cds;
    login_skyview = data.getLearnerDetails.login_skyview;
    is_admin = data.getLearnerDetails.is_admin;
  }

  const access = {
    ih: true,
    design: login_cds,
    sv: login_skyview,
    "user-management": is_admin,
  };

  return (
    <Disclosure as="nav" className={classNames("absolute top-0 w-full z-30", (IS_ROLLEASE || IS_BBLIND) ? "bg-gray-100 text-gray-700" : "bg-gray-600 text-gray-100")}>
      {({ open }) => (
        <>
          <div className="px-4 mx-auto">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center sm:px-2 lg:px-0 flex-1">
                <div className="flex-shrink-0 flex items-center">
                  <div
                    onClick={() => history.push("/home")}
                    className="text-xl font-normal logo-icon sm:text-2xl"
                    style={{ color: (IS_ROLLEASE || IS_BBLIND) ? "#055B7F" : "white" }}
                  >
                    MINDSTRENGTH
                  </div>
                  {IS_ROLLEASE && <>
                    <div className="px-2 text-lg lg:px-3" style={{
                      fontFamily: "'Allura', cursive",
                    }}>for</div>
                    <img alt="Rollease" src="https://www.rolleaseacmeda.com/app_themes/landing/images/rolleaseacmeda.png" className="h-6 sm:h-6" />
                  </>}
                  {IS_BBLIND && <>
                      <div className="px-2 text-lg lg:px-3" style={{
                          fontFamily: "'Allura', cursive",
                      }}>for</div>
                      <img alt="Rollease" src="https://d3iycmmv8yrrdd.cloudfront.net/media-content/bb-logo.png" className="h-8 sm:h-8" />
                  </>}
                </div>
                <div className="hidden lg:flex lg:ml-6 flex-1 justify-end">
                  <div className="flex space-x-4">
                    {products.map((product) => {
                      const name = product.url.split("/")[1];
                      if (
                        !window.location.pathname.match(
                          product.url.split("/")[1]
                        ) &&
                        (access as any)[name as any]
                      ) {
                        return (
                          <a
                            href={product.url}
                            target="_blank"
                            className={classNames("px-3 py-2 text-sm font-medium rounded-md animate__animated animate__slideInDown animate__delay-2s",
                              (IS_ROLLEASE || IS_BBLIND) ? "text-gray-700" : "hover:bg-gray-700 hover:text-white text-gray-100"
                            )}
                          >
                            {product.name}
                          </a>
                        );
                      } else {
                        return null;
                      }
                    })}

                    {data?.getLearnerDetails.id === 4 && data.getLearnerDetails.first_name === "Suresh" && <a
                      href="https://window.mindstrength.co/sv/token/4816dd90-21ed-11e1-b941-a304db0e31b6"
                      target="_blank"
                      className={classNames("px-3 py-2 text-sm font-medium rounded-md animate__animated animate__slideInDown animate__delay-2s",
                        (IS_ROLLEASE || IS_BBLIND) ? "text-gray-700" : "hover:bg-gray-700 hover:text-white text-gray-100"
                      )}
                    >
                      SkyView 2
                    </a>}
                  </div>
                </div>
              </div>

              {/* <Menu.Button
                ref={buttonRef}
                className="p-1.5 text-white hover:bg-gray-800 rounded-full focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-white shadow-none"
              > */}



              <div className="flex items-center">
                {/* <div
                  className="relative mr-4 text-white lg:hidden"
                  onClick={() => history.push("/messenger")}
                >
                  <ChatAlt2Icon className="w-6 h-6" aria-hidden="true" />
                  {!!data?.getUnreadCountConversations.unread_conversations && (
                    <div className="absolute bg-red-500 w-2.5 h-2.5 rounded-full right-0 top-0"></div>
                  )}
                </div> */}
                {/* </Menu.Button> */}

                <div className="hidden lg:block">
                  <div className="flex items-center">
                    <Menu as="div" className="relative z-50 flex-shrink-0 ml-4">
                      <div>
                        {/* <Menu.Button
                          ref={buttonRef}
                          className="p-2 text-white hover:bg-gray-800 rounded-full focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-white shadow-none"
                        >
                          {/* <BellIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          /> */}
                        {/* {!!data?.getUnreadCountConversations
                            .unread_notifications && (
                            <div className="absolute bg-red-500 w-2.5 h-2.5 rounded-full right-1 top-1"></div>
                          )}
                        </Menu.Button> */}
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-85"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 flex py-1 mt-2 overflow-auto origin-top-right bg-white rounded-md shadow-lg w-96 focus:outline-none"
                          style={{ height: 600, width: 420 }}
                        >
                          <NotificationsScreen
                            closeNotificationsDrawer={() => setShowNotifications(false)}
                          />
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {/* <Menu as="div" className="relative z-50 flex-shrink-0 ml-4">
                      <div>
                        <Menu.Button
                          ref={buttonRef}
                          className="p-1.5 text-white hover:bg-gray-800 rounded-full focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-white shadow-none"
                        >
                          <ChatAlt2Icon
                            className={classNames("w-6 h-6", (ENV as any) === "rollease" ? "text-gray-700" : "")}
                            aria-hidden="true"
                          />
                          {!!data?.getUnreadCountConversations
                            .unread_conversations && (
                            <div className="absolute bg-red-500 w-2.5 h-2.5 rounded-full right-1 top-1"></div>
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-85"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 flex py-1 mt-2 overflow-auto origin-top-right bg-white rounded-md shadow-lg w-96 focus:outline-none"
                          style={{ height: 600, width: 420 }}
                        >
                          <MsMessenger buttonRef={buttonRef} />
                        </Menu.Items>
                      </Transition>
                    </Menu> */}

                    <Menu as="div" className="relative z-50 flex-shrink-0 ml-4">
                      <div className="">
                        <Menu.Button className="flex text-sm text-white bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          {!loading && data?.getLearnerDetails && (
                            <div>
                              {data.getLearnerDetails && (
                                <UserAvatar
                                  user={data.getLearnerDetails as any}
                                />
                              )}
                            </div>
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/settings"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Preferences
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/password-reset"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Change Password
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/login"
                                onClick={() => {
                                  localStorage.removeItem("token");
                                  client.clearStore();
                                }}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>

                <div className="flex lg:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center text-gray-400 rounded-md sm:p-2 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {products.map((product) => {
                if (!window.location.pathname.match(product.url)) {
                  const name = product.url.split("/")[1];
                  if (
                    !window.location.pathname.match(
                      product.url.split("/")[1]
                    ) &&
                    (access as any)[name as any]
                  ) {
                    return (
                      <a
                        target="_blank"
                        href={product.url}
                        className={classNames("block px-3 py-2 text-base font-medium rounded-md",
                          (IS_ROLLEASE || IS_BBLIND) ? "text-gray-700" : "hover:bg-gray-700 hover:text-white text-gray-100"
                        )}
                      >
                        {product.name}
                      </a>
                    );
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })}
            </div>
            <div className="hidden h-5 h-6 h-8 h-10"></div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <UserAvatar user={data?.getLearnerDetails as any} />
                </div>
              </div>
              <div className="px-2 mt-3 space-y-1">
                <Disclosure.Button className="inline-flex items-center justify-center text-gray-400 rounded-md sm:p-2 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <Link
                    to="/settings"
                    className={classNames("block px-3 py-2 text-base font-medium rounded-md",
                      (IS_ROLLEASE || IS_BBLIND) ? "text-gray-700" : "hover:bg-gray-700 hover:text-white text-gray-100"
                      )}
                  >
                    Preferences
                  </Link>
                </Disclosure.Button>
                <Link
                  to="/login"
                  onClick={() => {
                    localStorage.removeItem("token");
                  }}
                  className={classNames("block px-3 py-2 text-base font-medium rounded-md",
                    (IS_ROLLEASE || IS_BBLIND) ? "text-gray-700" : "hover:bg-gray-700 hover:text-white text-gray-100"
                      )}
                >
                  Sign Out
                </Link>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
