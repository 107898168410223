import React from "react";
import Typist from "react-typist";
import "./animated-typed-text-with-pause.scss";
import ReactHtmlParser from "react-html-parser";
import { getFontSize } from "../../../AppFont";

const domparser = new DOMParser();
export const AnimatedTypedTextWithPause = (props: any) => {
  const { block } = props;
  let text: string = block.data.text;
  let textList = text.split(".");
  textList = textList.filter(t => !!t).map((t: string, index: number) => index === textList.length - 1 ? t : t + ".");
  
  return (
    <Typist
      className="animated-text"
      cursor={{
        blink: true,
        show: false,
        hideWhenDone: true,
        hideWhenDoneDelay: 10,
      }}
      avgTypingDelay={40}
      onTypingDone={() => {
        props.showNextBlock();
      }}
    >
      {textList.map((t: string, index: number) => {
        return (
          <span style={{
            fontSize: getFontSize(),
          }} key={index}>
            {ReactHtmlParser(t)}
            <Typist.Delay ms={1000} />
          </span>
        );
      })}
      <Typist.Delay ms={1500} />
    </Typist>
  );
};
