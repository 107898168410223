import { motion } from "framer-motion";
import React from "react";
import {
  InitiativeWithProgressOutput,
  UnitEntity,
} from "../../../generated/graphql";
import playbutton from "../../../images/playbutton.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./initiative-item.scss";
import { getFontSize } from "../../../AppFont";
import classNames from "classnames";

interface Props {
  initiative: UnitEntity;
  onClick: Function;
  allInitiatives?: InitiativeWithProgressOutput[];
  hideStartButton?: boolean;
  progress?: number;
  noLeftMargin?: boolean;
  makeBold?: boolean;
  showProgressBar: boolean;
}

const INITIATIVE_ICON_SRC =
  "https://cdn.picpng.com/icon/play-icon-free-wallpaper-vector-65968.png";

export const InitiativeItem = (props: Props) => {
  const { initiative } = props;
  let buttonText: string;

  const childInitiatives = (props.allInitiatives || []).filter((ai) => {
    return (
      ai.unit.is_private === 1 && ai.unit.parent_unit == +props.initiative.id
    );
  });

  let showButton = true;

  let allChildInitiativesCompleted = true;
  childInitiatives.forEach((i) => {
    if (i.progress < 100) {
      allChildInitiativesCompleted = false;
    }
  });

  if (!allChildInitiativesCompleted) {
    showButton = false;
  }

  if ((props.progress || 0) >= 100 && props.initiative.is_private) {
    showButton = false;
  }

  if (props.progress === 0) {
    buttonText = "START";
  } else if (props.progress! >= 100 && allChildInitiativesCompleted) {
    buttonText = "ReView";
  } else {
    buttonText = "RESUME";
  }

  return (
    <motion.div
      onClick={() => (showButton ? props.onClick() : null)}
      layoutId={`initiative-${initiative.id}`}
      className="initiative-item"
    >
      <div className="initiative-details">
        {props.showProgressBar && (
          <div className="initiative-icon">
            <CircularProgressbar
              value={props.progress!}
              strokeWidth={12}
            // text={`${props.progress}%`}
            />
          </div>
        )}

        <div
          style={{
            fontSize: getFontSize(),
          }}
          className={classNames(
            props.makeBold ? "font-bold" : "",
            props.noLeftMargin ? "" : "px-3",
          )}
        >
          {initiative.is_private === 1
            ? initiative.reassigned_display_name
            : initiative.name}
        </div>
      </div>
      {showButton && (
        <div
          className="initiative-button"
          style={{
            opacity: props.hideStartButton ? 0 : 1,
            fontSize: getFontSize(),
          }}
        >
          <div className="learn-button">{buttonText}</div>
        </div>
      )}
    </motion.div>
  );
};
