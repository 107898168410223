import React, { useEffect, useState } from "react";
import { ConversationDetails } from "../conversations/components/conversation-details/conversation-details";
import { eventBus } from "../../../../event-bus/event-bus";

export const ChatWrapper = () => {
  useEffect(() => {
    eventBus.on("CHAT_CONVERSATION_SELECTED", onChatConversationSelected);

    eventBus.on("CHAT_CONVERSATION_CLOSED", onChatConversationClosed);
  }, []);

  const onChatConversationSelected = (details: { conversation_id: number }) => {
    console.log("on conversation selected: ", details);
    const { conversation_id } = details;
    setConversationId(conversation_id);
  };

  const onChatConversationClosed = () => {
    setConversationId(0);
  };

  const [conversationId, setConversationId] = useState(0);
  return (
    <>{conversationId !== 0 && <ConversationDetails conversation_id={conversationId} />}</>
  );
};
