import { CheckCircleIcon, PlayIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useState } from "react";
import { Slideshow5 } from "./sli/five";
import { Slideshow4 } from "./sli/four";
import { Slideshow1 } from "./sli/one";
import { Slideshow7 } from "./sli/seven";
import { Slideshow6 } from "./sli/six";
import { Slideshow3 } from "./sli/three";
import { Slideshow2 } from "./sli/two";

const checklist = [
  {
    title: "What are blinds?",
    slide: Slideshow1,
  },
  {
    title: "Wooden blinds",
    slide: Slideshow1,
  },
  {
    title: "Faux wooden blinds",
    slide: Slideshow1,
  },
  {
    title: "Metal venetian blinds",
    slide: Slideshow1,
  },
  {
    title: "Roller blinds",
    slide: Slideshow1,
  },
  {
    title: "Roman blinds",
    slide: Slideshow1,
  },
  {
    title: "Illusion blinds",
    slide: Slideshow1,
  },
];

export const Slide2 = () => {
  const [Myslide, setFrame] = useState(checklist[0].slide);
  const [step, setStep] = useState(0);

  return (
    <div
      className="absolute inset-0 z-50 flex"
      style={{
        background: "#ccbcae",
      }}
    >
      <div className="flex-1 flex text-base overflow-auto p-8">
        {step === 0 && <Slideshow1 />}
        {step === 1 && <Slideshow2 />}
        {step === 2 && <Slideshow3 />}
        {step === 3 && <Slideshow4 />}
        {step === 4 && <Slideshow5 />}
        {step === 5 && <Slideshow6 />}
        {step === 6 && <Slideshow7 />}
        </div>
      <div className="flex-1 bg-gray-200 py-10">
        {checklist.map((item, index) => {
          return (
            <div
              className={classNames(
                "flex items-center py-8 px-4",
                index === step ? "bg-gray-300" : ""
              )}
            >
              {index <= step && (
                <CheckCircleIcon
                  onClick={() => {
                    setFrame(item.slide);
                    setStep(index);
                  }}
                  className="text-green-500 w-10 h-10 cursor-pointer"
                />
              )}
              {index > step && (
                <PlayIcon
                  onClick={() => {
                    setFrame(item.slide);
                    setStep(index);
                  }}
                  className="text-red-500 w-10 h-10 cursor-pointer"
                />
              )}
              <div className="w-6"></div>
              <div className="text-xl pl-4">{item.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
