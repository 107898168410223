import { gql, useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import * as React from "react";
import { MsLoader } from "../../components/header/screens/ms-loader/ms-loader";
import { ReferenceDocumentEntity, UserEntity } from "../../generated/graphql";
import { UserAvatar } from "../../components/header/common/user-avatar/user-avatar";

interface UsersData {
  usersUMEmployee: UserEntity[];
  }
const GET_USERS_DATA = gql`
query usersUMEmployee {
  usersUMEmployee {
    id
    email
    username
    first_name
    last_name
    avatar_url
    initials @client
  }
}
`;
const SEND_MDF_LINK_EMAIL = gql`
  mutation SendMdfLinkToUser(
    $user_id: Int!
    $doc_id: Int!
    $document_name: String!
    $url: String!
  ) {
    sendMdfLinkToUser(
      user_id: $user_id
      doc_id: $doc_id
      document_name: $document_name
      url: $url
    )
  }
`;
interface Props {
  document: ReferenceDocumentEntity;
}

export const MdfShare = (props: Props) => {
  const { loading, error, data, refetch } = useQuery<UsersData>(GET_USERS_DATA);
    const [sendMdfLinkToUser] = useMutation(SEND_MDF_LINK_EMAIL , {
        onCompleted(data: any) {
            notification.success({message: 'Mdf sent successfuly'})
        }
      });
  const docname = props.document.document_name;
  const docid = props.document.id;
  let url = document.location.href.split("ih")[0] + "ih/custom-refdoc/" + docid;
  if (loading || !data) {
    return <MsLoader />;
  }
  return (
    <div style={{ maxHeight: "60vh", overflow: "auto" }}>
      <div className="text-gray-400 px-6 py-2">Members</div>
        {data.usersUMEmployee
        .slice()
        .sort((a, b) => a.first_name.localeCompare(b.first_name))
        .map((user: any) => (
            <div
            key={user.id}
            className="relative hover:bg-gray-100 rounded-md px-5 py-3 flex items-center space-x-3"
          >
            <div className="flex-shrink-0">
              <UserAvatar user={user} />
            </div>
            <div className="flex-1 min-w-0">
              <div className="text-sm font-medium text-gray-900">
                {user.first_name} {user.last_name}
              </div>
              <div className="text-xs text-gray-500 truncate">{user.email}</div>
              {/*</a>*/}
            </div>
            <button
              onClick={() => {
                console.log(user.first_name);
                sendMdfLinkToUser({
                  variables: {
                    doc_id: +docid,
                    user_id: user.id,
                    document_name: docname,
                    url: url,
                  },
                });
              }}
              type="button"
              className="send inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send
            </button>
          </div>

        ))}
         
    </div>

  )  
      
    
};
