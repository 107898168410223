import React, { useEffect, useState } from "react";
import { FadeIn } from "../../fade-in/fade-in";
import { RefdocItem } from "../../refdoc-item/refdoc-item";
import { TypeText } from "../../type-text/type-text";
import "./animated-refdoc.scss";

export const AnimatedRefdoc = (props: any) => {
  console.log("Props: ", props);
  const data = props.block.data;
  const extension = data.url.split(".").pop();
  // useEffect(() => {
  //   if (props.showNextBlock) {

  //   }
  // }, []);
  const [isRefdocShown, setShowRefdoc] = useState(false);
  return (
    <div>
      <TypeText onComplete={() => setShowRefdoc(true)}>
        Related to this, here is an interesting file that i think you will find
        helpful.
      </TypeText>
      {isRefdocShown && (
        <FadeIn onComplete={() => props.showNextBlock()}>
          <RefdocItem name={data.name} url={data.url} type="FILE" />
        </FadeIn>
      )}
    </div>
  );
};
