import React from "react";

export const Slideshow1 = () => {
  return (
    <div>
      Simply put, blinds are a window furnishing which – in many cases – you can
      move along different axis to allow light into a room, or to block it out.
      They come in different shapes, forms and materials with each option
      offering something different to the previous or next. Whether they simply
      move up and down – or have the option the tilt forward and backward
      horizontally – blinds add a range of benefits to a space and can make a
      real statement to your décor.
    </div>
  );
};
