import React, { EffectCallback, useEffect, useState } from "react";
import { AnimatedList } from "./animated-list/animated-list";
import { AnimatedImage } from "./animated-image/animated-image";
import { AnimatedTypedText } from "./animated-typed-text/animated-typed-text";
import { AnimatedTypedTextWithPause } from "./animated-typed-text-with-pause/animated-typed-text-with-pause";
import { AnimatedVideo } from "./animated-video/animated-video";
import { AnimatedDelimiter } from "./animated-delimiter/animated-delimiter";
import { AnimatedRefdoc } from "./animated-refdoc/animated-refdoc";
import { AnimatedLink } from "./animated-link/animated-link";
import { getHtmlFromBlocks } from "../../util/getHtmlFromBlocks";

interface Props {
  data: any;
  onComplete?: Function;
  pauseTextOnPeriod?: boolean;
  mode: string; // EXPLANATION/QUESTION/INTRO
  initiative_id?: number;
  section_id?: number;
  question_id?: number;
  onVideoStart?: () => void;
  onVideoPause?: () => void;
  isPreview?: boolean;
}

// showAcknowledgeButton

export const AnimatedContent = (props: Props) => {
  let { data } = props;
  let blocks = props.data.blocks || [];

  // blocks = [...blocks, {
  //   type: "fileUrl",
  //   data: {
  //     url: "https://dkq0mu3wemxfu.cloudfront.net/ef2dd8dd-712c-40fe-9a4c-97cc85cc1c95.pdf",
  //     name: "Paradigm for Leadership"
  //   }
  // }]

  blocks = blocks.filter((block: any) => {
    return (
      [
        "delimiter",
        "image",
        "video",
        "list",
        "paragraph",
        "header",
        "fileUrl",
        "link",
      ].indexOf(block.type) > -1
    );
  });
  const [blocksCompleted, setBlocksCompleted] = useState(0);
  const showNextBlock = () => {
    if (blocksCompleted === blocks.length - 1) {
      if (props.onComplete) props.onComplete();
    } else {
      setBlocksCompleted(blocksCompleted + 1);
    }
  };
  const getJsxForBlock = (block: any, index: number) => {
    if (block.type === "list") {
      return <AnimatedList showNextBlock={showNextBlock} block={block} />;
    } else if (block.type === "image") {
      return (
        <AnimatedImage
          isLastBlock={index === blocks.length - 1}
          showAcknowledgeButton={props.mode === "EXPLANATION"}
          showNextBlock={showNextBlock}
          block={block}
        />
      );
    } else if (block.type === "link") {
      return (
        <AnimatedLink
          showNextBlock={showNextBlock}
          block={block}
        />
      );
    } else if (block.type === "video") {
      return (
        <AnimatedVideo
          initiative_id={props.initiative_id}
          section_id={props.section_id}
          question_id={props.question_id}
          mode={props.mode}
          showNextBlock={showNextBlock}
          block={block}
          onVideoPause={props.onVideoPause}
          onVideoStart={props.onVideoStart}
        />
      );
    } else if (["paragraph", "header"].indexOf(block.type) > -1) {
      if (props.pauseTextOnPeriod) {
        return (
          <AnimatedTypedTextWithPause
            showNextBlock={showNextBlock}
            block={block}
          />
        );
      } else {
        return (
          <AnimatedTypedText showNextBlock={showNextBlock} block={block} />
        );
      }
    } else if (block.type === "delimiter") {
      return <AnimatedDelimiter showNextBlock={showNextBlock} block={block} />;
    } else if (block.type === "fileUrl") {
      return <AnimatedRefdoc showNextBlock={showNextBlock} block={block} />;
    }
  };

  const useMountEffect = (fun: EffectCallback) => useEffect(fun, []);

  const html = getHtmlFromBlocks({ blocks });

  useEffect(() => {
    if(props.isPreview) {
      if(props.onComplete) {
        props.onComplete();
      }
    }
  }, []);

  useMountEffect(() => {
    if (blocks.length === 0) {
      if (props.onComplete) props.onComplete();
    }
  });

  if(props.isPreview) {
    return (
      <div dangerouslySetInnerHTML={{ __html: html.join("") }} />
    )
  }

  return (
    <div>
      {blocks.map((block: any, index: any) => {
        if (index <= blocksCompleted) {
          return <div key={index}>{getJsxForBlock(block, index)}</div>;
        } else {
          return <div key={index}></div>;
        }
      })}
    </div>
  );
};
