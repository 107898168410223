import React, { useEffect, useState } from "react";
import "./conversation-list.scss";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  ChatConversationEntity,
  ConversationsListOutput,
  InitiativeWithProgressOutput,
} from "../../../../../../generated/graphql";
import moment from "moment";
import { useHistory } from "react-router";
import { Drawer } from "antd";
import { ConversationDetails } from "../conversation-details/conversation-details";
import { InitiateConversation } from "../initiate-conversation/initiate-conversation";
import { eventBus } from "../../../../../../event-bus/event-bus";
import { ConversationLastMessage } from "./last-message";
import { ConversationParticipantNames } from "./participants-names";
import { LastMessageDate } from "./last-message-date";
import { Popover } from "@headlessui/react";
import { MsLoader } from "../../../ms-loader/ms-loader";
import { AvatarStack } from "../../../../common/avatar-stack/avatar-stack";
const isMobile = window.innerWidth < 550;

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const GET_CONVERSATIONS = gql`
  query GetConversations($initiative_id: Int!) {
    getConversations(initiative_id: $initiative_id) {
      conversation {
        id
        participants {
          id
          first_name
          last_name
          username
          avatar_url
        }
        initiative {
          id
          name
        }
        last_message {
          id
          message
          createdAt
        }
      }
      is_read
    }
    getInitiativesForLearnerWithProgress {
      unit {
        id
        name
        is_private
      }
    }
  }
`;

const token = localStorage.getItem("token");
let userId: number;

if (token) {
  const details = JSON.parse(atob(token!.split(".")[1]));
  userId = details.userId;
} else {
  userId = 0;
}

interface ConversationsData {
  getConversations: ConversationsListOutput[];
  getInitiativesForLearnerWithProgress: InitiativeWithProgressOutput[];
}

const shouldShowNew = !window.location.pathname.match("idc");

interface Props {
  closeConversationListDrawer?: Function;
  initiative_id: number;
  buttonRef?: any;
}

export const ConversationList = (props: Props) => {
  const { loading, error, data, refetch } = useQuery<ConversationsData>(
    GET_CONVERSATIONS,
    {
      variables: {
        initiative_id: props.initiative_id ? props.initiative_id : 0,
      },
    }
  );

  const [selectedConversationId, setSelectedConversationId] = useState(0);
  const [showInitiateConversation, setShowInitiateConversation] =
    useState(false);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refetch();
    }, 2000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const history = useHistory();

  if (loading) {
    return <MsLoader />;
  }

  const onConversationClick = (conversation: ChatConversationEntity) => {
    if (isMobile) {
      history.push(`conversations/${conversation.id}`);
    } else {
      eventBus.dispatch("CHAT_CONVERSATION_SELECTED", {
        conversation_id: conversation.id,
      });
    }
    if (props.closeConversationListDrawer) {
      props.closeConversationListDrawer();
    }
  };

  const conversations: ConversationsListOutput[] =  JSON.parse(JSON.stringify(data?.getConversations)).sort((a: ConversationsListOutput, b: ConversationsListOutput) => {
    // if(b.is_read && !a.is_read) {
    //   return -1;
    // }
    if(a.conversation.last_message && b.conversation.last_message) {
      return new Date(b.conversation.last_message.createdAt).getTime() - new Date(a.conversation.last_message.createdAt).getTime();
    }
    return -1;
  })

  return (
    <div className="conversation-list">
      {/* <div className="heading">
        <div>Your conversations</div>
        {shouldShowNew && (
          <div
            className="new-conversation"
            onClick={() => {
              if (isMobile) {
                history.push(`initiate-conversation`);
              } else {
                console.log("DISPATCHING");
                eventBus.dispatch("SHOW_INITIATE_CONVERSATION", {});
              }
              if (props.closeConversationListDrawer) {
                props.closeConversationListDrawer();
              }
            }}
          >
            New
          </div>
        )}
      </div> */}
      <div className="">
        {[1].map((num) => (
          <div className="">
            {conversations.map((conversationObject) => {
              const { conversation } = conversationObject;
              const participants = conversation.participants.filter(
                (p) => p.id !== userId
              );
              return (
                <div
                  onClick={() => {
                    console.log("LALALALALALALALA");
                    if (props.buttonRef) {
                      console.log("YESS");
                      props.buttonRef.current?.click();
                    } else {
                      console.log("NOOO");
                    }
                    onConversationClick(conversation);
                  }}
                  key={conversation.id}
                  className={classNames(
                    "flex py-3 pr-3 justify-start items-start w-full conversation-list-item cursor-pointer",
                    conversationObject.is_read
                      ? "bg-transparent"
                      : "bg-blue-100"
                  )}
                >
                  <div className="w-12 flex justify-end ">
                    <AvatarStack users={participants} />
                  </div>
                  <div className="flex-1 pl-3">
                    <div className="flex text-sm">
                      <div className="flex-1">
                        {conversation.initiative ? (
                          conversation.initiative?.name
                        ) : (
                          <ConversationParticipantNames users={participants} />
                        )}
                        {}
                      </div>
                      <LastMessageDate conversation={conversation} />
                    </div>
                    <div className="text-xs text-gray-500">
                      {conversation.initiative && (
                        <ConversationParticipantNames users={participants} />
                      )}
                      <ConversationLastMessage
                        message={conversation.last_message?.message}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
