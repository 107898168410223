import React from "react";
import { useHistory } from "react-router-dom";
import { CustomRefdocModal } from "./custom-refdoc-modal";

export const CustomRefdoc = (props: any) => {
  const document_id = +(props as any).match.params.id;
  const history = useHistory();

  return <CustomRefdocModal closePreview={() => history.replace("/home") } refDocId={document_id}  />
};
