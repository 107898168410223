import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useState } from "react";
import { AnimatedTypedTextWithPause } from "../../components/animated-content/animated-typed-text-with-pause/animated-typed-text-with-pause";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";

const text1 = `Wooden blinds – often called wooden Venetian blinds – are an elegant and charming choice. These blinds offer superb functionality, durability, and the timeless beauty of natural wood. Additionally, wooden blinds give buyers the flexibility to enjoy accurate light control and privacy. To ensure we limit our impact upon nature, we use woods sourced only from responsibly managed forests to protect local communities and the global environment.`;

const pairs = [
  {
    title: "Wooden blinds ",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/real-wooden-blinds.jpg",
    text: `Wooden blinds – often called wooden Venetian blinds – are an elegant and charming choice. These blinds offer superb functionality, durability, and the timeless beauty of natural wood. Additionally, wooden blinds give buyers the flexibility to enjoy accurate light control and privacy. To ensure we limit our impact upon nature, we use woods sourced only from responsibly managed forests to protect local communities and the global environment.`,
  },
  {
    title: "Faux wooden blinds",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/faux-wood-blinds.jpg",
    text: `When a space is prone to high levels of moisture, real wood can sometimes warp or crack. To avoid this happening in kitchens or bathrooms, look to faux wooden blinds. Faux wooden blinds provide water resistance, stain resistance and are as easy to clean as real wooden blinds. Faux wooden blinds don’t compromise on style either, they’re just as fashionable, and functional, as their real wood counterparts.`,
  },
  {
    title: "Venetian blinds",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/metal-venetian-blinds.jpg",
    text: `Venetian blinds aren’t limited to real or faux wood. Metal Venetian blinds are built using high quality aluminium which is tough against moisture and wear and tear. The horizontal slats not only make them stylish and sleek, but like their wooden counterparts, they are equally functional too. The slats can be accurately angled to allow for the perfect balance between privacy and light.`,
  },
  {
    title: "Roller blinds",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/roller-blinds.jpg",
    text: `Roller blinds are one of the most popular styles of blinds, enjoyed in thousands of homes across the UK. Their uncomplicated aesthetic makes them a great canvas for colour and pattern, letting style conscious decorators add a touch of their own personalities to their décor with minimal fuss. These blinds contribute a great blend of privacy, light control, and thermal efficiency with blackout, thermal and moisture proof fabrics available to make the most out of your blinds`,
  },
];

export const Slide4 = () => {
  //   const [index, setIndex] = useState(0);
  //   const [pair, setPair] = useState(pairs[index]);

  //   const next = () => {
  //     setIndex(index + 1);
  //     setPair(pairs[index + 1]);
  //   };

  //   const previous = () => {
  //     setIndex(index - 1);
  //     setPair(pairs[index - 1]);
  //   };
  const [isTyped, setIsTyped] = useState(false);
  const [isPairTyped, setIsPairTyped] = useState(false);
  const [pair, setPair] = useState<any>();
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="absolute inset-0 z-40 flex text-2xl bg-gray-300">
      {(!pair || (!!pair && !isPairTyped)) && (
        <img
          className={classNames(isTyped ? "opacity-100" : "opacity-0")}
          src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/real-wooden-blinds.jpg"
        />
      )}
      {pair && isPairTyped && (
        <img
          className={classNames(isTyped ? "opacity-100" : "opacity-0")}
          src={pair.image}
        />
      )}

      <div className="p-10">
        {
          <div>
            <div>Wooden blinds</div>
            <div className="text-lg">
              <AnimatedTypedTextWithPause
                showNextBlock={() => setIsTyped(true)}
                onComplete={() => {}}
                block={{
                  data: {
                    text: text1,
                  },
                }}
              />
              <br />
              {isTyped && (
                <div className="flex">
                  <MstrButton
                    onClick={() => {
                      setPair(pairs[1]);
                      setIsPairTyped(false);
                    }}
                    text="Faux wooden blinds"
                    className="w-72"
                  />
                  <MstrButton
                    onClick={() => {
                      setPair(pairs[2]);
                      setIsPairTyped(false);
                    }}
                    text="Venetian blinds"
                    className="w-72"
                  />
                </div>
              )}
              {pair && (
                <div className="pt-6">
                  <div className="text-2xl">{pair.title}</div>
                  <AnimatedTypedTextWithPause
                    key={pair.title}
                    showNextBlock={() => setIsPairTyped(true)}
                    onComplete={() => setIsPairTyped(true)}
                    block={{
                      data: {
                        text: pair.text,
                      },
                    }}
                  />
                  <br />
                  {isPairTyped && (
                    <MstrButton
                      className="w-72"
                      onClick={() => {
                        setShowVideo(true);
                      }}
                      text="Show video"
                    />
                  )}
                </div>
              )}
              {showVideo && (
                <div className="absolute inset-10 z-50 flex">
                  <video
                    src="https://d205v7va10brr8.cloudfront.net/f87685c9-157b-4455-bf42-fb1613be3f93.mp4"
                    controls
                  />
                  <div>
                    <XIcon
                      onClick={() => setShowVideo(false)}
                      className="w-6 h-6"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
