import React from "react";
import { UserEntity } from "../../../../generated/graphql";
import { UserAvatar } from "../user-avatar/user-avatar";

interface Props {
  users: UserEntity[];
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const AvatarStack = (props: Props) => {
  const { users } = props;

  return (
    <div className="flex -space-x-1 relative z-0 overflow-hidden">
      {users.map((user, index) => {
        return (
          <UserAvatar size={users.length > 1 ? "6" : "10"} user={user} />
        );
      })}
    </div>
  );
};
