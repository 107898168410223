import React, { useEffect, useState } from "react";
import { ConversationDetails } from "../conversations/components/conversation-details/conversation-details";
import { eventBus } from "../../../../event-bus/event-bus";
import { Drawer } from "antd";
import { InitiateConversation } from "../conversations/components/initiate-conversation/initiate-conversation";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";

export const InitiateConversationWrapper = () => {
  useEffect(() => {
    eventBus.on("SHOW_INITIATE_CONVERSATION", showInitiateConversation);
  }, []);

  const showInitiateConversation = () => {
    console.log("SHOW INITIATEV CONVERSATIONSSS");
    setInitiateConversation(true);
  };

  const [initiateConversation, setInitiateConversation] = useState(false);
  return (
    <>
      {/* {initiateConversation && (
        <Drawer
          mask={false}
          placement="right"
          className="ih-conversation-drawer"
          title="Initiate conversation"
          width={"50%"}
          onClose={() => setInitiateConversation(false)}
          destroyOnClose={true}
          visible={initiateConversation}
        >
          <InitiateConversation
            closeInitiateConversationDrawer={() =>
              setInitiateConversation(false)
            }
          />
        </Drawer>
      )} */}
      {initiateConversation && (
        <Transition.Root show={initiateConversation} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-30 inset-0 overflow-y-auto"
            onClose={() => setInitiateConversation(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setInitiateConversation(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Need any help with the initiative?
                      </Dialog.Title>
                      <div className="mt-2">
                        {/* <p className="text-sm text-gray-500">
                          Select an initiatives to start a conversation with the
                          <b>initiative lead</b>.
                        </p> */}
                      </div>
                    </div>
                  </div>

                  <InitiateConversation
                    closeInitiateConversationDrawer={() =>
                      setInitiateConversation(false)
                    }
                  />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};
