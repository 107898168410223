import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { QuickFlowModal } from "./quickflow-modal";

export const QuickFlowOpen = (props: any) => {
  const document_id = +(props as any).match.params.id;
  const history = useHistory();
  
  const allowedHosts = ["product.mindstrength.co", "demo.mindstrength.co", "localhost"];

  if(allowedHosts.indexOf(window.location.hostname) === -1) {
    return (
      <Redirect to="/home" />
    )
  }

  return (
    <div className="flex flex-1" style={{ background: "#cfdaf5" }}>
      <div className="max-w-xl mx-auto flex flex-1 w-full bg-white">
        <QuickFlowModal hideClose={true} hideShare={true} closePreview={() => history.replace("/login")} refDocId={document_id} />
      </div>
    </div>
  )
};
