import { motion } from "framer-motion";
import React from "react";

interface Props {
  list: any[];
  onAnimationComplete?: Function;
}

const duration = 0.25;

const listVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: duration,
    },
  },
};

const listItemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration * 2.5,
    },
  },
};

export const StaggerList = (props: Props) => {
  return (
    <motion.div
      onAnimationComplete={() => {
        if (props.onAnimationComplete) props.onAnimationComplete();
      }}
      variants={listVariants}
      initial="hidden"
      animate="visible"
    >
      {props.list.map((item, index) => {
        return (
          <motion.div variants={listItemVariants} key={index}>
            {item}
          </motion.div>
        );
      })}
    </motion.div>
  );
};
