import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

interface Props {
  delay: number;
}

export const ShowAfter: React.FunctionComponent<Props> = (props) => {
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, props.delay);
  }, []);
  if (!showContent) {
    return <></>;
  }
  return <>{props.children}</>;
};
