import React from "react";
import "./mstr-button-outline.scss";

interface Props {
  text: string;
  onClick: any;
  showLoading?: boolean;
  disabled?: boolean;
  width?: number;
}

export const MstrButtonOutline = (props: Props) => {
  let styles: any = {};
  if (props.width) {
    styles.width = props.width;
  }
  return (
    <div
      style={{...styles}}
      role="button"
      className={`mstr-button-outline ${props.disabled ? "disabled" : ""}`}
      onClick={props.onClick}
    >
      <div style={{ flex: 1 }}>{props.text}</div>
      {props.showLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};
