import React from "react";
import { FadeIn } from "../../fade-in/fade-in";

export const AnimatedLink = (props: any) => {
  console.log("AnimatedLink Props: ", props);
  const data = props.block.data;
  const link = data.link;
  const title = data.meta.title;

  return (
    <div>
      {(
        <FadeIn onComplete={() => props.showNextBlock()}>
          <a href={link}>{title}</a>
        </FadeIn>
      )}
    </div>
  );
};
