import React from "react";
import { getFontSize } from "../../AppFont";
import "./mstr-button.scss";

interface Props {
  text: string;
  onClick: any;
  showLoading?: boolean;
  disabled?: boolean;
  width?: number;
  className?: string;
}

export const MstrButton = (props: Props) => {
  let styles: any = {};
  if (props.width) {
    styles.width = props.width;
  }

  return (
    <div
      style={{ ...styles, fontSize: getFontSize(), }}
      role="button"
      className={`mstr-button ${props.disabled ? "disabled" : ""} ${props.className ? props.className : ""}`}
      onClick={props.onClick}
    >
      <div className="flex items-center justify-center flex-1">
        <div>{props.text}</div>
        {props.showLoading === true && (
          <div className="loader-container">
            <div className="loader-icon"></div>
          </div>
        )}
      </div>
    </div>
  );
};
