import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import "./first-login.scss";
import { useHistory } from "react-router-dom";

const UPDATE_LEARNER_PASSWORD = gql`
  mutation UpdateLearnerPasswordFromResetToken(
    $token: String!
    $password: String!
  ) {
    updateLearnerPasswordFromResetToken(token: $token, password: $password) {
      error
    }
  }
`;

interface UpdatePasswordData {
  updateLearnerPasswordFromResetToken: {
    error: boolean;
  };
}

interface TokenDetailsData {
  getForgotTokenDetails: string;
}

export const FirstLoginScreen = (props: any) => {
  console.log("Params: ", (props as any).match.params);
  const token_str = (props as any).match.params.id;
  const [showFields, setShowFields] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const history = useHistory();

  const GET_TOKEN_DETAILS = `
    query {
      getForgotTokenDetails(token: "${token_str}")
    }
  `;

  const { data, error, loading } = useQuery<TokenDetailsData>(
    gql(GET_TOKEN_DETAILS)
  );

  const [changePassword] = useMutation<UpdatePasswordData>(
    UPDATE_LEARNER_PASSWORD,
    {
      update: (cache, response) => {
        setShowLoading(false);
        console.log("Got update password: ", response);
        setShowSuccess(true);
      },
    }
  );

  if (data) {
    if (!data.getForgotTokenDetails.length) {
      console.log("Not valid token");
      // history.push("/home");
    }
  }

  const updatePassword = () => {
    setShowLoading(true);
    changePassword({
      variables: {
        password,
        token: token_str,
      },
    });
  };

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => setShowFields(true)}>
        Hi, let's set you up with a secure password. Remember to keep it safe
        with you.
      </TypeText>
      <br />
      {showFields && (
        <FadeIn onComplete={() => setShowButton(true)}>
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </form>
          </div>
        </FadeIn>
      )}
      <br />
      {showButton && !showSuccess && (
        <FadeIn>
          <MstrButton
            onClick={updatePassword}
            disabled={password.length < 6}
            width={250}
            showLoading={showLoading}
            text="Set password"
          ></MstrButton>
        </FadeIn>
      )}
      <br />
      {showSuccess && (
        <TypeText
          onComplete={() => {
            setTimeout(() => history.push("/home"), 2000);
          }}
        >
          Your password has been reset successfully.
        </TypeText>
      )}
    </div>
  );
};
