import { ArrowRightIcon } from "@heroicons/react/outline";
import React from "react";

export const AssistantFabButton = () => {
    return (
        <button
            type="button"
            className="flex items-center space-x-2 rounded-full bg-blue-500 p-3 px-5 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
            <div className="text-base">Start</div>
            <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
    )
}