import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { TypeText } from "../../components/type-text/type-text";
import AnimatedText from "react-animated-text-content";
import { AnimatedImage } from "../../components/animated-content/animated-image/animated-image";

const pairs = [
  {
    title: "Wooden blinds ",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/real-wooden-blinds.jpg",
    text: `Wooden blinds – often called wooden Venetian blinds – are an elegant and charming choice. These blinds offer superb functionality, durability, and the timeless beauty of natural wood. Additionally, wooden blinds give buyers the flexibility to enjoy accurate light control and privacy. To ensure we limit our impact upon nature, we use woods sourced only from responsibly managed forests to protect local communities and the global environment.`,
  },
  {
    title: "Faux wooden blinds",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/faux-wood-blinds.jpg",
    text: `When a space is prone to high levels of moisture, real wood can sometimes warp or crack. To avoid this happening in kitchens or bathrooms, look to faux wooden blinds. Faux wooden blinds provide water resistance, stain resistance and are as easy to clean as real wooden blinds. Faux wooden blinds don’t compromise on style either, they’re just as fashionable, and functional, as their real wood counterparts.`,
  },
  {
    title: "Venetian blinds",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/metal-venetian-blinds.jpg",
    text: `Venetian blinds aren’t limited to real or faux wood. Metal Venetian blinds are built using high quality aluminium which is tough against moisture and wear and tear. The horizontal slats not only make them stylish and sleek, but like their wooden counterparts, they are equally functional too. The slats can be accurately angled to allow for the perfect balance between privacy and light.`,
  },
  {
    title: "Roller blinds",
    image:
      "https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/roller-blinds.jpg",
    text: `Roller blinds are one of the most popular styles of blinds, enjoyed in thousands of homes across the UK. Their uncomplicated aesthetic makes them a great canvas for colour and pattern, letting style conscious decorators add a touch of their own personalities to their décor with minimal fuss. These blinds contribute a great blend of privacy, light control, and thermal efficiency with blackout, thermal and moisture proof fabrics available to make the most out of your blinds`,
  },
];

export const Slide3 = () => {
  const [index, setIndex] = useState(0);
  const [pair, setPair] = useState(pairs[index]);

  const next = () => {
    setIndex(index + 1);
    setPair(pairs[index + 1]);
  };

  const previous = () => {
    setIndex(index - 1);
    setPair(pairs[index - 1]);
  };

  return (
    <div className="absolute inset-0 z-50 flex text-2xl bg-gray-300">
      <div className="flex-1 flex items-center justify-center">
        <img src={pair.image} />
        {/* <AnimatedImage
          showNextBlock={() => {}}
          block={{
            data: {
              url: pair.image,
            },
          }}
        /> */}
      </div>
      <div className="flex-1 bg-gray-200 flex flex-col justify-center px-10 relative">
        <div className="text-blue-500 text-3xl pb-4">{pair.title}</div>
        <AnimatedText
          type="words"
          animation={{
            x: "200px",
            y: "-20px",
            scale: 1.1,
            ease: "ease-in-out",
          }}
          animationType="lights"
          interval={0.06}
          duration={0.8}
          tag="p"
          className="animated-paragraph"
          includeWhiteSpaces
          threshold={0.1}
          rootMargin="20%"
        >
          {pair.text}
        </AnimatedText>
        {index !== 0 && (
          <div
            onClick={() => {
              previous();
            }}
            className="absolute w-14 h-14 rounded-full bg-gray-600 bottom-4 left-4 flex items-center justify-center cursor-pointer"
          >
            <ArrowLeftIcon className="w-8 h-8 text-white" />
          </div>
        )}

        {index < pairs.length - 1 && (
          <div
            onClick={() => {
              next();
            }}
            className="absolute w-14 h-14 rounded-full bg-gray-600 bottom-4 right-4 flex items-center justify-center cursor-pointer"
          >
            <ArrowRightIcon className="w-8 h-8 text-white" />
          </div>
        )}
      </div>
    </div>
  );
};
