import { ChatIcon } from "@heroicons/react/outline";
import React from "react";

export const AssistantMessageInput = () => {
    return (
        <div>
            <div className="relative mt-2 rounded-md shadow-sm">
                <input
                    type="text"
                    name="message"
                    id="message"
                    className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Type your question here ..."
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ChatIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
            </div>
        </div>
    )
}