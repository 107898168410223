import React, { useEffect } from "react";
import Typist from "react-typist";
import { getFontSize } from "../../AppFont";
import "./type-text.scss";

interface Props {
  delay?: number;
  startDelay?: number;
  stylingClass?: string;
  onComplete: Function;
  fontSize?: number;
  isPreview?: boolean;
}

export const TypeText: React.FunctionComponent<Props> = (props) => {
  const { fontSize } = props;

  useEffect(() => {
    if(props.isPreview) {
      if(props.onComplete) {
        props.onComplete();
      }
    }
  }, []);

  if (props.isPreview) {
    return (
      <div
        className={`${props.stylingClass ? props.stylingClass : ""}`}
      >
        <span style={{
          fontSize: !fontSize ? getFontSize() : `${fontSize}px`,
        }}>{props.children}</span>
      </div>
    )
  }

  return (
    <Typist
      className={`${props.stylingClass ? props.stylingClass : ""}`}
      startDelay={props.startDelay ? props.startDelay : 100}
      cursor={{
        blink: true,
        hideWhenDone: true,
        hideWhenDoneDelay: 10,
      }}
      avgTypingDelay={40}
      onTypingDone={() => props.onComplete()}
    >
      <span style={{
        fontSize: !fontSize ? getFontSize() : `${fontSize}px`,
      }}>{props.children}</span>
      <Typist.Delay ms={!!props.delay ? props.delay : 1500} />
    </Typist>
  );
};
