import React from "react";
import { Disclosure } from "@headlessui/react";
import "./umheader_dark.scss";
import { useHistory } from "react-router";
import classNames from "classnames";

const IS_ROLLEASE = window.location.host.indexOf("rollease") > -1;

export const UMHeaderDarkOpenNoLogout = () => {
  const history = useHistory();
  
  return (
    <Disclosure as="nav" className={classNames("absolute top-0 w-full z-30", IS_ROLLEASE ? "bg-gray-100 text-gray-700" : "bg-gray-600 text-gray-100")}>
      {({ open }) => (
        <>
          <div className="px-4 mx-auto">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center sm:px-2 lg:px-0 flex-1">
                <div className="flex-shrink-0 flex items-center">
                  <div
                    className="text-xl font-normal logo-icon-no-cursor sm:text-2xl"
                    style={{ color: IS_ROLLEASE ? "#055B7F" : "white" }}
                  >
                    MINDSTRENGTH
                  </div>
                  {IS_ROLLEASE && <>
                    <div className="px-2 text-lg lg:px-3" style={{
                      fontFamily: "'Allura', cursive",
                    }}>for</div>
                    <img alt="Rollease" src="https://www.rolleaseacmeda.com/app_themes/landing/images/rolleaseacmeda.png" className="h-6 sm:h-6" />
                  </>}
                </div>
                <div className="hidden lg:flex lg:ml-6 flex-1 justify-end">
                  <div className="flex space-x-4 open-header">
                    
              
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <div className="hidden lg:block">
                  <div className="flex items-center">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};
