import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GetInitiativeSectionOutput,
  // SectionEntity,
} from "../../generated/graphql";
// import { SectionScreen } from "../section/section.screen";
import { FullScreenLoader } from "../../components/full-screen-loader/full-screen-loader";
import { InitiativeCompleted } from "../../components/initiative-completed/initiative-completed";
// import { useHistory } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";
import { ClosingSequence } from "../../components/closing-sequence/closing-sequence";
import { getHideHeader } from "../../AppFont";

interface NextSectionData {
  getSectionForInitiative: GetInitiativeSectionOutput;
}

const GET_NEXT_SECTION_DETAILS = gql`
  mutation NextSectionForInitiative($initiative_id: Int!) {
    getSectionForInitiative(initiative_id: $initiative_id) {
      section {
        id
      }
      isInitiativeComplete
      sortid
    }
  }
`;

export const InitiativeScreen = (props: any) => {
  const initiativeId = +props.match.params.id;
  const [isInitiativeCompleted, setInitiativeCompleted] = useState(false);
  const [getNextSectionMutation] = useMutation<NextSectionData>(
    GET_NEXT_SECTION_DETAILS,
    {
      update: (cache, response) => {
        const nextSection = response.data?.getSectionForInitiative.section;
        const sortId = response.data?.getSectionForInitiative.sortid;
        if (response.data?.getSectionForInitiative.isInitiativeComplete) {
          setInitiativeCompleted(true);
        } else if (nextSection && nextSection.id) {
          const hideHeader = getHideHeader();
          if(hideHeader) {
            history.replace(
              `/initiative/${initiativeId}/section/${nextSection.id}/sort/${sortId}?hideHeader`
            );
          } else {
            history.replace(
              `/initiative/${initiativeId}/section/${nextSection.id}/sort/${sortId}`
            );
          }
        } else {
          history.push("/home");
        }
      },
    }
  );

  const history = useHistory();

  useEffect(() => {
    getNextSectionMutation({
      variables: {
        initiative_id: initiativeId,
      },
    });
  }, []);

  if (isInitiativeCompleted) {
    return (
      <div style={{ padding: 20 }}>
        <ClosingSequence initiativeId={initiativeId} />
      </div>
    );
  }

  return <FullScreenLoader />;

  // const { loading, error, data } = useQuery<NextSectionData>(
  //   GET_NEXT_SECTION_DETAILS,
  //   {
  //     variables: { initiative_id: initiativeId, user_id: 1 },
  //   }
  // );

  // if (loading) return <FullScreenLoader />;
  // if (error) return <div>ERROR</div>;

  // if (data?.getSectionForInitiative.isInitiativeComplete) {
  //   return (
  //     <div style={{ padding: 20 }}>
  //       <ClosingSequence initiativeId={initiativeId} />
  //     </div>
  //   );
  // }

  // if (data?.getSectionForInitiative.section) {
  //   const sectionId = data?.getSectionForInitiative.section.id!;
  //   const sortId = data.getSectionForInitiative.sortid;
  //   return <Redirect to={`/initiative/${initiativeId}/section/${sectionId}/sort/${sortId}`} />;
  // }

  // return <div>Something went wrong</div>;
};
