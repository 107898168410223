import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AnimatedContent } from "../../../components/animated-content/animated-content";
import { FadeIn } from "../../../components/fade-in/fade-in";
import { MstrButton } from "../../../components/mstr-button/mstr-button";
import { TypeText } from "../../../components/type-text/type-text";
import { LearnerDetailsOutput, UnitEntity } from "../../../generated/graphql";
import TTS from "../../../components/tts/tts";

interface Props {
  initiative: UnitEntity;
  learnerDetails: LearnerDetailsOutput;
  isPreview?: boolean;
}

const SEEN_INTRO = gql`
  mutation UpdateIntroSeen($initiative_id: Int!) {
    updateIntroSeen(initiative_id: $initiative_id)
  }
`;

export const InitiativeIntro = (props: Props) => {
  const history = useHistory();

  const [seenIntro] = useMutation(SEEN_INTRO);

  const initiativeFinalName = props.initiative.is_private === 1
  ? props.initiative.reassigned_display_name
  : props.initiative.name;
  const speechConnector = `Hi ${props.learnerDetails.first_name}! Welcome to the initiative: ${initiativeFinalName}. `;

  const startInitiative = () => {
    seenIntro({
      variables: {
        initiative_id: +props.initiative.id,
      },
    });
    history.replace(`/initiative/${props.initiative.id}`);
  };

  const [shouldShowIntro, setShouldShowIntro] = useState(false);
  const [isIntroShown, setIntroShown] = useState(false);
  return (
    <div className="initiative-intro-container">
      <div className="text-base font-bold pt-4">
        {props.initiative.is_private === 1
              ? props.initiative.reassigned_display_name
              : props.initiative.name}
      </div>
      <br />

      {props.initiative.intro && props.initiative.intro.blocks && (
        <TTS showTextLabel={true} id={props.initiative.id} connector={speechConnector} url="/api2/unit/speech" />
      )}

      <br />
      <div className="initiative-intro-connector mentor-communication">
        <TypeText
          onComplete={() => {
            setShouldShowIntro(true);
          }}
          isPreview={props.isPreview}
        >
          <div className="pb-1.5">Hi {props.learnerDetails.first_name}!</div>
          Welcome to the initiative:{" "}
          <b>
            {props.initiative.is_private === 1
              ? props.initiative.reassigned_display_name
              : props.initiative.name}
          </b>
          .
        </TypeText>
        <br />
      </div>
      {shouldShowIntro && (
        <div className="initiative-intro">
          <AnimatedContent
            mode="INTRO"
            onComplete={() => {
              setIntroShown(true);
            }}
            data={props.initiative.intro}
            isPreview={props.isPreview}
          />
        </div>
      )}

      {isIntroShown && (
        <div className="mt-5">
          <br />
          <FadeIn>
            <MstrButton disabled={props.isPreview} text="Let's Start" onClick={startInitiative} />
          </FadeIn>
        </div>
      )}
    </div>
  );
};
