import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { InitiateConversation } from "../../../../components/header/screens/conversations/components/initiate-conversation/initiate-conversation";
import { InitiativeConversationWithUser } from "../../../../components/header/screens/conversations/components/initiative-conversation-with-user/initiative-conversation-with-user";
import { ConversationScreen } from "../../../../components/header/screens/conversations/conversations";
import { eventBus } from "../../../../event-bus/event-bus";

const isMobile = window.innerWidth < 550;
const shouldShowNew = window.location.pathname.match("ih");

interface Props {
  buttonRef?: any;
}

const tabs = [
  { name: "All", href: "#", mode: "ALL" },
  { name: "Leads", href: "#", mode: "LEADERS" },
  { name: "Users", href: "#", mode: "USERS" },
  { name: "Support", href: "#", mode: "SUPPORT" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const MsMessenger = (props: Props) => {
  const history = useHistory();
  const [mode, setMode] = useState<string>(tabs[0].mode);

  const { buttonRef } = props;

  return (
    <div className="flex flex-col flex-1 pt-2 overflow-hidden">
      <div className="flex justify-center px-4 mb-3 font-semibold text-gray-600">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              onClick={() => setMode(tab.mode)}
              key={tab.name}
              className={classNames(
                tab.mode === mode
                  ? "bg-gray-200 text-gray-800"
                  : "text-gray-600 hover:text-gray-800",
                "px-3 py-2 font-medium text-sm rounded-md"
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>
        {/* <div className="text">{mode === "MESSAGES" ? "Messages" : ""}</div> */}
        {/* <div
          onClick={() => {
            setMode(mode === "NEW" ? "MESSAGES" : "NEW");
          }}
          className="text-blue-500 cursor-pointer text"
        >
          {mode === "NEW" ? "Cancel" : "New"}
        </div> */}
      </div>
      <div className="flex flex-1 overflow-auto">
        {mode === "ALL" && (
          <ConversationScreen buttonRef={buttonRef} initiative_id={0} />
        )}

        {mode === "LEADERS" && (
          <div>
            <InitiateConversation buttonRef={buttonRef} />
          </div>
        )}

        {mode === "USERS" && (
          <InitiativeConversationWithUser buttonRef={buttonRef} />
        )}

        {mode === "SUPPORT" && (
          <div className="px-4 text-lg">Coming soon</div>
        )}
      </div>
      {/* <div className="pr-3 my-1 font-semibold text-right text-blue-400 cursor-pointer">
        Need help?
      </div> */}
    </div>
  );
};
