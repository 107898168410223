import React, { useState } from "react";
import { UserEntity } from "../../../../generated/graphql";

interface Props {
  user: UserEntity;
  size?: string;
}

const getRandomColor = () => {
  let colors = ["#0283ba", "#e1242c", "#01c7d4", "#a55aa8", "#ffbc79"];
  const randomIndex = Math.floor(Math.random() * colors.length);
  let randomColor = colors[randomIndex];
  return randomColor;
}

export const UserAvatar = (props: Props) => {
  const { user } = props;
  const [bgColor] = useState(getRandomColor());
  let initials = (user as any).initials;
  if (!initials) {
    initials = "";
    if (user.first_name) {
      initials = initials + user.first_name[0];
    }
    if (user.last_name) {
      initials = initials + user.last_name[0];
    }
  }

  const size = props.size || "10";
  if (user.avatar_url) {
    return (
      <img
        className={`h-${size} w-${size} rounded-full object-cover`}
        src={user.avatar_url!}
        alt=""
      />
    );
  } else {
    return (
      <div
        style={{
          background: bgColor,
        }}
        className={`h-${size} w-${size} rounded-full bg-gray-700 text-white flex justify-center items-center ${size != "10" ? "text-xs" : ""}`}
      >
        {initials.toUpperCase()}
      </div>
    );
  }
};
