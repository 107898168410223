import React from "react";
import { motion } from "framer-motion";
import "./section-content.partial.scss";
import { SectionEntity } from "../../generated/graphql";
import { getFontSize } from "../../AppFont";

interface Props {
  fade: boolean;
  section: SectionEntity;
}

export const SectionContentPartial = (props: Props) => {
  return (
    <motion.div id={`mstr-section-${props.section.id}`}>
      {/* <div className="section-header">{props.section.section_header}</div> */}
      {/* <br /> */}
      <div className={props.fade ? "fade-section" : ""}>
        <div
          className="section-content"
          style={{
            fontSize: getFontSize(),
          }}
          dangerouslySetInnerHTML={{ __html: props.section.content_html! }}
        ></div>
      </div>
    </motion.div>
  );
};
