import React, { useEffect, useState } from "react";
import { RefdocPreview } from "../../components/refdoc-item/refdoc-preview/refdoc-preview";
import { motion } from "framer-motion";
import ReactImageMagnify from "react-image-magnify";
import { MstrButton } from "../../components/mstr-button/mstr-button";

export const RefdocPreviewScreen = (props: any) => {
  const documentId = +props.match.params.id;
  const url = decodeURIComponent(props.match.params.url);

  const [extension, setExtension] = useState("");
  const [isDownloadable, setDownloadable] = useState(false);

  const [dimension, setDimension] = useState<{
    height: number;
    width: number;
  }>();

  const onImgLoad = (data: any) => {
    console.log({
      height: data.target.offsetHeight,
      width: data.target.offsetWidth,
    });
    setDimension({
      height: data.target.offsetHeight,
      width: data.target.offsetWidth,
    });
  };

  useEffect(() => {
    let fileparts = url.split(".");
    let extensionName = fileparts[fileparts.length - 1];
    extensionName = extensionName.toLowerCase();
    let extension = "";
    if (["xlsx", "xls", "csv"].indexOf(extensionName) > -1) {
      extension = "xls.png";
      setDownloadable(true);
    } else if (
      ["mp4", "m4a", "mov", "ogg", "wmv", "webm"].indexOf(extensionName) > -1
    ) {
      if (["m4a", "ogg", "wmv", "webm"].indexOf(extensionName) > -1) {
        setDownloadable(true);
      }
      extension = "smallvid.png";
    } else if (["pdf"].indexOf(extensionName) > -1) {
      extension = "pdf.png";
      // if (this.deviceInfo.browser === 'IE') {
      //   doc.download = true;
      // }
    } else if (["doc", "docx", "odt"].indexOf(extensionName) > -1) {
      setDownloadable(true);
      extension = "doc.png";
    } else if (["mp3"].indexOf(extensionName) > -1) {
      extension = "smallaud.png";
    } else if (
      ["jpg", "png", "gif", "webp", "tiff", "jpeg"].indexOf(extensionName) > -1
    ) {
      extension = "smallimg.png";
    } else if (["ppt", "pptx"].indexOf(extensionName) > -1) {
      extension = "ppt.png";
    } else {
      extension = "file.png";
    }

    if (
      ["pdf.png", "smaallvid.png", "smallimg.png"].indexOf(extension) === -1
    ) {
      console.log("REDIRECTTTT");
      window.open(url, "_blank");
    }
    setExtension(extension);
  }, []);

  return (
    <div>
      {/* {documentId} - {url} - {extension} */}
      {extension === "pdf.png" && (
        <RefdocPreview id={documentId} url={url} extension={extension} />
      )}
      {extension === "smallvid.png" && (
        <motion.video
          id={`refdoc-${documentId}`}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          initial={{ opacity: 0, y: 200, scale: 0.8 }}
          transition={{ duration: 1 }}
          controls
          style={{ width: "100%" }}
          src={url}
        />
      )}

      {extension === "smallimg.png" && !dimension && (
        <div style={{ opacity: 0, position: "absolute" }}>
          <img src={url} onLoad={onImgLoad} />
        </div>
      )}

      {extension === "smallimg.png" && dimension && (
        <ReactImageMagnify
          enlargedImagePosition="over"
          {...{
            smallImage: {
              alt: "",
              isFluidWidth: true,
              src: url,
              onLoad: () => {},
            },
            largeImage: {
              src: url,
              width: dimension.width,
              height: dimension.width,
            },
          }}
        />
      )}

      {["pdf.png", "smallvid.png", "smallimg.png"].indexOf(extension) < 0 && (
        <div style={{ padding: "0px 20px" }}>
          <MstrButton text="Open" onClick={() => window.open(url)} />
        </div>
      )}
    </div>
  );
};
