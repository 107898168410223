import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FadeIn } from "../../components/fade-in/fade-in";
import { MstrButton } from "../../components/mstr-button/mstr-button";
import { TypeText } from "../../components/type-text/type-text";
import "./password-reset.scss";
import { useHistory } from "react-router-dom";
import lock from "./lock.png";
import { UMHeaderDark } from "../../components/header/umheader_dark";
import Password from "antd/lib/input/Password";

const UPDATE_LEARNER_PASSWORD = gql`
  mutation UpdateLearnerPassword($password: String!) {
    updateLearnerPassword(password: $password) {
      error
    }
  }
`;

interface UpdatePasswordData {
  updateLearnerPassword: {
    error: boolean;
  };
}

export const PasswordResetScreen = () => {
  const [input, setInput] = useState({
    password: '',
    confirmPassword: ''
  });
 
  const [error, setError] = useState({
    password: '',
    confirmPassword: ''
  })
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showFields, setShowFields] = useState(false);

  const history = useHistory();

  const [changePassword] = useMutation<UpdatePasswordData>(
    UPDATE_LEARNER_PASSWORD,
    {
      update: (cache, response) => {
        setShowLoading(true);
        console.log("Got update password: ", response);
        setShowSuccess(true);
        // history.push("/home");
      },
    }
  );
  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }
  const validateInput = (e: any) => {
    let { name, value } = e.target;
    setError(prev => {
      const errObj : any = { ...prev, [name]: "" };
 
      switch (name) {
 
        case "password":
          if (!value) {
            errObj[name] = "Please enter Password.";
          }
            else if(value.length <6) {
              errObj[name] ="*Password should be minimum 6 characters long"
            }
           else if (input.confirmPassword && value !== input.confirmPassword) {
            errObj["confirmPassword"] = "Password and Confirm Password does not match.";
          } else {
            errObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
          }
          break;
 
        case "confirmPassword":
          if (!value) {
            errObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            errObj[name] = "Password and Confirm Password does not match.";
          }
          break;
 
        default:
          break;
      }
 
      return errObj;
    });
  }

  const updatePassword = () => {
    setShowLoading(true);
    changePassword({
      variables: {
        password: input.password,
      },
    });
  };

  return (
    <div className="min-w-full">
      <UMHeaderDark />
      <div className="flex min-h-full min-w-full flex-col justify-center align-middle py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-1 mb-2 text-center text-3xl font-bold tracking-tight text-gray-900">
            <TypeText onComplete={() => setShowFields(true)}>
              {" "}
              Hi, let's set you up with a new password. Remember to keep it safe
        with you.
            </TypeText>
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="off"
                    value={input.password}
                    onChange={onInputChange}
                    required
                    onBlur={validateInput}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  {error.password && (
                    <div className="p-2">{error.password}</div>
                  )}
                </div>
              </div>
               <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm New Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="confirmPassword"
                    type="password"
                    autoComplete="off"
                    value={input.confirmPassword}
                    onChange={onInputChange}
                    required
                    onBlur={validateInput}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                      {error.confirmPassword && <div className='p-2 text-red-700'>{error.confirmPassword}</div>}
                </div>
              </div>

              <div className="flex items-center justify-between"> </div>

              <div>
                <FadeIn>
                      <MstrButton
                        className=" flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={updatePassword}
                        disabled={input.password.length < 6 || input.password != input.confirmPassword }
                        width={385}
                        showLoading={showLoading}
                        text="Set password"
                      ></MstrButton>
                    </FadeIn>
              </div>
            </form>
            <div className="mt-6"></div>
          {showSuccess && (
                <TypeText
                  onComplete={() => {
                    setTimeout(() => history.push("/home"), 1000);
                  }}
                >
                  <div className="flex flex-col justify-center items-center mt-6">
                    Your password has been reset successfully.
                  </div>
                </TypeText>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

