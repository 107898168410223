import React from "react";

export const Slideshow6 = () => {
  return (
    <div>
      <img src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/roman-blinds.jpg" />
      <div className="py-6">
        Few blinds can compete with the beauty and sophistication of quality
        Roman blinds. Buy fitting one into your window, you can instantly add a
        feeling of refinement into a space. They are a classy window furnishing
        and a more compact alternative to curtains available in a wide range of
        colours, patterns, finishes and materials. These include eco-friendly
        recycled fabrics which will help you to minimise the impact of your home
        on the environment. Moreover, the design of Roman blinds allows you to
        enjoy options including thermal and blackout linings.
      </div>
    </div>
  );
};
