const saved = localStorage.getItem("fontsize");
let fontSize = saved ? +saved : 16;

export const getFontSize = () => {
  return fontSize;
};

export const setFontSize = (size: number) => {
  localStorage.setItem("fontsize", size.toString());
  fontSize = size;
};

const hideHeaderSaved = localStorage.getItem("hideHeader");
let hideHeader = hideHeaderSaved && hideHeaderSaved === "1" ? true : false;

export const getHideHeader = () => {
  // Returning false. Header will not be hidden until the feature is properly working.
  return false;
  return !!+hideHeader;
};

export const setHideHeader = (hide: boolean) => {
  localStorage.setItem("hideHeader", hide ? "1" : "0");
  hideHeader = hide;
};
