import React from "react";

export const Slideshow2 = () => {
  return (
    <div>
      <div className="py-6">
        Wooden blinds – often called wooden Venetian blinds – are an elegant and
        charming choice. These blinds offer superb functionality, durability,
        and the timeless beauty of natural wood. Additionally, wooden blinds
        give buyers the flexibility to enjoy accurate light control and privacy.
        To ensure we limit our impact upon nature, we use woods sourced only
        from responsibly managed forests to protect local communities and the
        global environment.
      </div>
      <img src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/real-wooden-blinds.jpg" />
    </div>
  );
};
