import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ChapterEntity, ReferenceDocumentEntity } from "../../../generated/graphql";
import { RefdocItem } from "../../../components/refdoc-item/refdoc-item";

const showCount = localStorage.getItem("showrefcount");

interface Props {
  chapter: ChapterEntity;
  firstInitiativename?: string;
  refdocCount: {[k: string]: number};
}

export const RefLibraryItem = (props: Props) => {
  const refdocCount = props.refdocCount || {};
  const [isOpen, setIsOpen] = useState(props.chapter.chapter_name === props.firstInitiativename);
  const toggleOpen = () => {
    console.log("Toggle: ", isOpen);
    setIsOpen(!isOpen);
  };

  let totalClicks = 0;
  props.chapter.refdocs.forEach(doc => {
    totalClicks += refdocCount[doc.id] || 0;
  });

  let documents: ReferenceDocumentEntity[] = JSON.parse(JSON.stringify(props.chapter.refdocs));
  documents = documents.sort((a, b) => {
    if(!refdocCount[a.id]) {
      return 1;
    }

    if(!refdocCount[b.id]) {
      return -1;
    }

    return refdocCount[a.id] > refdocCount[b.id] ? -1 : 1
  });

  useEffect(() => {
    setIsOpen(props.chapter.chapter_name === props.firstInitiativename);
  }, [props.firstInitiativename]);

  return (
    <motion.div
      style={{
        marginBottom: 10,
        // background: "rgba(214, 214, 214, 0.5)",
        // borderRadius: 5,
        // padding: 10,
      }}
      className="bg-gray-100 rounded-sm p-3"
    >
      <div
        style={{
          fontSize: 16,
          cursor: "pointer",
          fontWeight: "bold",
          color: "#2196F3",
        }}
        onClick={toggleOpen}
      >
        {props.chapter.chapter_name} {showCount ? totalClicks : ""}
      </div>
      {/* <br/> */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, height: 0 }}
          >
            {documents.map((document: ReferenceDocumentEntity) => (
              <div style={{ paddingLeft: 10 }} key={document.id}>
                <RefdocItem
                  refdocCount={refdocCount}
                  id={document.id}
                  name={document.document_name}
                  url={document.url}
                  type={document.reftype}
                />
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
