import React from "react";

export const Slideshow7 = () => {
  return (
    <div>
      <img src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/illusion-blinds.jpg" />
      <div className="py-6">
        Create functionality and flare in any home with a well-made illusion
        blind. These stylish blinds are built from a combination of sheer and
        opaque fabrics which allow daylight to filter into a space while
        allowing a view to the outside. With the twist of a wand however, the
        layers come together to deliver total privacy. This versatility makes
        them an excellent choice for rooms on the ground floor where you may
        work or entertain during the day, but then relax come the night-time.
        They look beautiful when set against patio doors, whilst working
        particularly well for bi-folds.
      </div>
    </div>
  );
};
