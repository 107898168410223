import React, { useEffect } from "react";

export const AnimatedDelimiter = (props: any) => {
  useEffect(() => {
    if (props.showNextBlock) {
      props.showNextBlock();
    }
  }, []);
  return (
    <div className="mstr-delimiter">
      <br />
    </div>
  );
};
