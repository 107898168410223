import React, { useState } from "react";
import { FadeIn } from "../fade-in/fade-in";
import { MstrButton } from "../mstr-button/mstr-button";
import "./answer-text.scss";
import { IOnAnswerSelect } from "../question/question.component";
import classNames from "classnames";
import { TypeText } from "../type-text/type-text";
import loaderImage from "../../images/loading-animation.gif";
import { AnimatePresence } from "framer-motion";
import { getFontSize } from "../../AppFont";

interface Props {
  onAnswerSelect: IOnAnswerSelect;
  isQuestionResponseLoading: boolean;
  isCorrect: string;
  showLoading: boolean;
  isCurrentQuestion: boolean;
  isPreview?: boolean; // Don't allow user to attempt any question or event
}

export const AnswerText = (props: Props) => {
  const [isTextFieldShown, setTextFieldShown] = useState(false);
  const [answer_text, setAnswerText] = useState("");
  const [isAnswerClicked, setAnswerClicked] = useState(false);
  const [showLoadingDots, setShowLoadingDots] = useState(false);
  const getRandomTypingMessage = () => {
    const messages = [
      `That's an interesting answer. Let me think about it.`,
      `Okay, interesting. Let me think about your answer.`,
      `Let me think about that answer.`,
      `I appreciate that input, let me think about your answer.`,
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };

  const [typingMessage] = useState(getRandomTypingMessage());

  return (
    <div>
      <FadeIn animateHeight={true} onComplete={() => setTextFieldShown(true)}>
        <div
          style={{ fontSize: 10 }}
          className="bg-gray-200 font-bold uppercase text-gray-600 inline-block float-right mr-1 px-3 py-1 rounded-lg mb-4"
        >
          FREE TEXT BETA
        </div>
        <textarea
          rows={5}
          value={answer_text}
          style={{
            fontSize: getFontSize(),
          }}
          onChange={(e) => {
            if(props.isPreview) {
              return false;
            }
            setAnswerText(e.target.value);
          }}
          placeholder="Enter your answer here"
          className={classNames("answer-text mb-5 rounded-md border border-gray-400", props.isCorrect)}
        />
      </FadeIn>
      {isTextFieldShown && !isAnswerClicked && (
        <FadeIn>
          <MstrButton
            disabled={answer_text.length > 1 ? false : true}
            showLoading={props.showLoading}
            text="Answer"
            onClick={() => {
              if(props.isPreview) {
                return false;
              }
              setTimeout(() => props.onAnswerSelect(0, answer_text), 5000);
              setAnswerClicked(true);
            }}
          ></MstrButton>
        </FadeIn>
      )}
      <br />
      {isTextFieldShown && isAnswerClicked && (
        <div
          className={`mentor-communication ${
            props.isQuestionResponseLoading
              ? "show-loading-dots"
              : "hide-loading-dots"
          }`}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TypeText
            onComplete={() => setShowLoadingDots(true)}
            startDelay={1000}
            stylingClass={"freeTextLoader"}
          >
            <div>{typingMessage}</div>
            <div className="stage" style={{ paddingLeft: 25 }}>
              <div className="dot-elastic"></div>
            </div>
          </TypeText>
        </div>
      )}
    </div>
  );
};
