import React from "react";

export const Slideshow5 = () => {
  return (
    <div>
      <img src="https://www.blindsdirect.co.uk/blog/wp-content/uploads/2022/03/roller-blinds.jpg" />
      <div className="py-6">
        Roller blinds are one of the most popular styles of blinds, enjoyed in
        thousands of homes across the UK. Their uncomplicated aesthetic makes
        them a great canvas for colour and pattern, letting style conscious
        decorators add a touch of their own personalities to their décor with
        minimal fuss. These blinds contribute a great blend of privacy, light
        control, and thermal efficiency with blackout, thermal and moisture
        proof fabrics available to make the most out of your blinds
      </div>
    </div>
  );
};
