import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { AnswerEntity } from "../../generated/graphql";
import { Answer } from "../answer/answer";
import { answerVariants } from "../question/question.framer-variants";

interface Props {
  isQuestionResponseLoading: boolean;
  answers: AnswerEntity[];
  selectedAnswerId: number;
  isCurrentQuestion: boolean;
  isAttemptCorrect: string;
  onAnswerSelect: Function;
}

export const AnswerList = (props: Props) => {
  const { selectedAnswerId, isCurrentQuestion } = props;
  return (
    <AnimatePresence>
      {/* {props.isQuestionResponseLoading} */}
      {props.answers.map((answer: any, index: any) => {
        if (selectedAnswerId === 0 || selectedAnswerId === answer.id) {
          return (
            <motion.div
              className="answer-content"
              key={answer.id}
              variants={answerVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <Answer
                showLoading={
                  selectedAnswerId !== 0 &&
                  props.isQuestionResponseLoading &&
                  isCurrentQuestion
                }
                answer={answer}
                onAnswerSelect={props.onAnswerSelect}
                isAnswerSelected={selectedAnswerId === answer.id}
                isCorrect={props.isAttemptCorrect}
              />
              <br />
            </motion.div>
          );
        } else {
          return [];
        }
      })}
    </AnimatePresence>
  );
};
