import React from "react";
import { motion } from "framer-motion";
import "./animated-list.scss";
import { getFontSize } from "../../../AppFont";

const duration = 1;

const listVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: duration,
    },
  },
};

const listItemVariants = {
  hidden: {
    opacity: 0,
    x: -150,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration,
    },
  },
};

export const AnimatedList = (props: any) => {
  return (
    <motion.ul
      variants={listVariants}
      initial="hidden"
      animate="visible"
      onAnimationComplete={() => {
        props.showNextBlock();
      }}
    >
      {props.block.data.items.map((item: any, index: any) => (
        <motion.li
          style={{
            fontSize: getFontSize(),
          }}
          dangerouslySetInnerHTML={{ __html: item }}
          variants={listItemVariants}
          key={index}
        ></motion.li>
      ))}
    </motion.ul>
  );
};
