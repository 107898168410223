import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useHistory } from "react-router";
import { eventBus } from "../../../../../../event-bus/event-bus";
import { UserEntity } from "../../../../../../generated/graphql";
import { UserItem } from "../../../../common/user-item/user-item";
import { SearchField } from "../../../../common/user-search/user-search";
import { MsLoader } from "../../../ms-loader/ms-loader";

interface Props {
  buttonRef?: any;
}

const GET_USERS_DATA = gql`
  query Users {
    usersMessenger {
      id
      email
      username
      first_name
      last_name
      avatar_url
      initials @client
    }
  }
`;

const INITIATE_CONVERSATION = gql`
  mutation InitiateConversation($initiative_id: Int!, $section_id: Int!) {
    initiateConversation(
      initiative_id: $initiative_id
      section_id: $section_id
    ) {
      id
      section_id
      initiative_id
    }
  }
`;

interface UsersData {
  usersMessenger: UserEntity[];
}

const isMobile = window.innerWidth < 550;

const shouldShowUser = (user: UserEntity, searchText: string): boolean => {
  return (
    user.first_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
    user.last_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
    user.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  );
};

export const InitiativeConversationWithUser = (props: Props) => {
  const history = useHistory();
  const [initiateConversation] = useMutation(INITIATE_CONVERSATION, {
    update: (cache, response) => {
      console.log("Initiate Response: ", response);
      if (isMobile) {
        history.push(`/conversations/${response.data.initiateConversation.id}`);
      } else {
        eventBus.dispatch("CHAT_CONVERSATION_SELECTED", {
          conversation_id: response.data.initiateConversation.id,
        });
      }
    },
  });

  const { loading, error, data, refetch } = useQuery<UsersData>(GET_USERS_DATA);
  const [searchText, setSearchText] = useState("");
  if (loading || !data) {
    return <MsLoader />;
  }
  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <SearchField
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder="Search users"
      />
      <ul
        role="list"
        className="relative z-0 divide-y divide-gray-200 mb-0 flex-1 overflow-auto list-none"
      >
        {data.usersMessenger.map((user) => {
          if (shouldShowUser(user, searchText)) {
            return (
              <div
                onClick={() => {
                  initiateConversation({
                    variables: {
                      initiative_id: 0,
                      section_id: user.id,
                    },
                  });
                }}
              >
                <UserItem person={user} isSelected={false} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </ul>
    </div>
  );
};
