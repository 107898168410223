import { gql, useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TypeText } from "../../components/type-text/type-text";
import "./auto-login.scss";




interface LoginDataResponse {
  accessToken: string;
  error: boolean;
}

export const AutoLoginQFScreen = (props: any) => {
  console.log("Params: ", (props as any).match.params);
  const token_str = (props as any).match.params.id;
  const document_id = (props as any).match.params.documentid;
  
  const history = useHistory();
  const LOGIN_MUTATION = gql`
  mutation getJwtFromAuthToken($token: String!) {
    getJwtFromAuthToken(token: $token) {
      accessToken
      error
    }
  }
`;
  const [loginUser] = useMutation<LoginDataResponse>(
      LOGIN_MUTATION
      ,
      {
        update: (cache, response: any) => {
          console.log({
            response,
          });
          localStorage.setItem("token", response.data?.getJwtFromAuthToken?.accessToken!);
          history.push(`/quickflow/${document_id}`);
        },
      }
  );

  useEffect(() => {
    setTimeout(() => {
      loginUser({
        variables: {
          token: token_str
        },
      }).catch((err) => {
        console.log("Got Error: ", err);
      });
    }, 2000);
  }, [])

  return (
    <div style={{ padding: 20, flex: 1 }}>
      <TypeText onComplete={() => {}}>
        Please wait while we validate your credentials.
      </TypeText>
      <br />
      <br />
      
    </div>
  );
};
