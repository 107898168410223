import React from "react";
import { Maybe } from "graphql/jsutils/Maybe";

interface Props {
  message: Maybe<string>;
}

export const ConversationLastMessage = (props: Props) => {
  if (!props.message) {
    return null;
  }
  return <div className="text-xs text-blue-500 mt-1">{props.message}</div>;
};
